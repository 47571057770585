import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Page } from '../components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const AboutPage = () => {
  const { i18n } = useTranslation();
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchContent = async () => {
      const result = await axios.get(`/manuals/${i18n.language}/about.html`);
      setContent(result.data);
    };
    fetchContent();
  }, [i18n.language]);

  return (
    <Page>
      <div>
        <Helmet>
          <title>
            About us - AllCarParts - a wholesale online store of genuine &
            aftermarket car parts from all over the world
          </title>
          <meta
            name="description"
            content="Strategically located in United Arab Emirates, Dubai Free Zone.
              All OEM and aftermarket brands in one store - Toyota, Mitsubishi, Honda, Nissan, Mazda, Suzuki, Ford, Kia, Hyundai, Subaru, Mercedes,
              Lexus, Rover, Chevrolet, BMW, Isuzu, Renault, Daewoo, Land Rover, Daihatsu, etc."
          ></meta>
        </Helmet>
      </div>

      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Page>
  );
};

export default AboutPage;

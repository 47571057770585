import React, { useEffect, useState } from 'react';
import { Page } from '../components';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Grid, withStyles, Typography } from '@material-ui/core';
import { ContactSection } from '../components';

const ContactPage = ({ classes }) => {
  const { t } = useTranslation();
  return (
    <Page>
      <div>
        <Helmet>
          <title>
            Contacts - AllCarParts - visit us at our location or contact us
          </title>
          <meta
            name="description"
            content="At any time we are glad to see you at our office and warehouse, to hear from you. Contact us in case of any doubts or issues."
          ></meta>
        </Helmet>
      </div>

      <Grid container justifyContent="center" style={{ marginTop: '30px' }}>
        <Grid item>
          <ContactSection />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" classes={{ h5: classes.sectionSubHeading }}>
            {t('warehouse_location')}
          </Typography>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14475.248851731565!2d55.1204844!3d24.9043863!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f0b7a25d79281%3A0xb9131be62e727718!2sAllCarParts%20DWC-LLC!5e0!3m2!1sen!2ses!4v1680647272981!5m2!1sen!2ses"
            className={classes.map}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid>
      </Grid>
    </Page>
  );
};
const styles = (theme) => {
  return {
    sectionSubHeading: {
      margin: '40px auto',
      fontWeight: 400,
      fontSize: '28px',
      textAlign: 'left',
      width: '80%',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        fontSize: '18px',
        margin: '20px auto',
      },
    },
    map: {
      display: 'block',
      margin: '0 auto',
      width: '80%',
      height: '825px',
      border: 'none',
      borderRadius: '36px',
      [theme.breakpoints.down('md')]: {
        height: '412px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '312px',
      },
    },
  };
};
export default withStyles(styles)(ContactPage);

import React from 'react';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const NewsCard = ({
  classes,
  content,
  title,
  subTitle,
  action,
  id,
  date,
  grey,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleButtonClick = () => {
    history.push(`/news/${id}`);
  };
  return (
    <Card className={grey ? classes.rootGrey : classes.root}>
      <CardHeader
        className={classes.header}
        title={
          <div className={classes.header}>
            <Typography variant="h6" component="div" className={classes.title}>
              {t('news')}
            </Typography>
            <Typography variant="subtitle1" component="div">
              {date}
            </Typography>
          </div>
        }
        subheader={
          <Typography variant="body2" className={classes.subTitle}>
            {subTitle}
          </Typography>
        }
      />
      <CardContent>
        <Typography variant="body2" className={classes.content}>
          {content}
        </Typography>
      </CardContent>
      <div className={classes.actions}>
        <Button className={classes.button} onClick={handleButtonClick}>
          {t('jump_to_news')}{' '}
          <ArrowForwardIcon style={{ fontSize: '16px', marginLeft: '10px' }} />{' '}
        </Button>
      </div>
    </Card>
  );
};
//TODO: LINK TO NEWS COMPONENT

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    width: '360px',
    height: '300px',
    borderRadius: '20px',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    margin: '30px auto',
    [theme.breakpoints.down('md')]: {},
  },
  rootGrey: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    width: '360px',
    height: '300px',
    borderRadius: '20px',
    backgroundColor: '#F5F5F7',
    boxShadow: 'none',
    margin: '30px auto',
    [theme.breakpoints.down('md')]: {},
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 'auto',
    alignItems: 'center',
    paddingRight: '1rem',
    paddingBottom: '1rem',
    '& > *': {
      color: '#007AFF',
      fontSize: '0.85rem',
      fontWeight: 500,
      textTransform: 'capitalize',
      textDecoration: 'none',
    },
  },
  button: {
    border: '1px solid #007AFF',
    borderRadius: '100px',
    width: '155px',
    height: '35px',
    '&:hover': {
      backgroundColor: '#007AFF',
      color: '#fff',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '22px',
    lineHeight: '26px',
  },
  content: {
    fontSize: '14px',
    fontWeight: 400,
  },
  subTitle: {
    marginTop: '16px',
    fontSize: '16px',
    fontWeight: 500,
  },

  iconContainer: {
    backgroundColor: '#007AFF',
    borderRadius: '12.5px',
    width: '50px',
    height: '50px',
    textAlign: 'center',
    '& > *': {
      height: '50px',
      color: '#ffffff',
      fontSize: '35px',
    },
  },
  avatar: {
    width: '50px',
    backgroundColor: '#007AFF',
    borderRadius: '10px',
  },
});

export default withStyles(styles)(NewsCard);

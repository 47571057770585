import React, { useState, useRef } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  TableContainer,
  Paper,
  Popover,
} from '@material-ui/core';
import moment from 'moment';
import { APP_DATE_FORMAT } from '../utils/date';

import { useTranslation } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';

import calendar_icon from '../assets/image/calendar_icon.png';

function ShipmentsTotalTable({
  classes,
  data,
  delivery,
  deliveryData,
  onSelect,
  onEditDelivery,
  onShipmentDateClick,
}) {
  const { t } = useTranslation();
  const currency = data.length ? data[0].currency : '';

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOnEditDelivery = () => {
    onEditDelivery();
    handlePopoverClose();
  };

  const open = Boolean(anchorEl);
  return (
    <TableContainer
      component={Paper}
      variant="rounded"
      className={classes.tableContainer}
    >
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.rowHead}>
            <TableCell>{t('delivery')}</TableCell>
            <TableCell>{t('places')}</TableCell>
            <TableCell>{t('weight_kg')}</TableCell>
            <TableCell>{t('volume_m3')}</TableCell>
            <TableCell>{t('volume_kg')}</TableCell>
            <TableCell>{t('amount_with_currency', { currency })}</TableCell>
            <TableCell>{t('schedule')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((value, i) => (
            <TableRow
              key={value.delivery}
              style={{ backgroundColor: i % 2 === 0 ? '#FBFBFB' : '#FFFFFF' }}
              className={
                delivery === value.delivery ? classes.selectedRow : classes.row
              }
              onClick={() => {
                !open && onSelect(value.delivery);
              }} //!open tackle weird behaviour of the table
            >
              <TableCell>
                <div>
                  {value.delivery} - {value.tariff}
                  <IconButton onClick={handlePopoverOpen}>
                    <InfoOutlinedIcon />
                  </IconButton>
                  <Popover
                    className={classes.popover}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                  >
                    <Typography variant="subtitle2">
                      {value.destination}, {deliveryData.city}, <br />
                      {deliveryData.days} {t('days')}, <br />
                      {value.transport} <br />
                    </Typography>

                    <Button
                      onClick={handleOnEditDelivery}
                      className={classes.popoverButton}
                    >
                      {t('edit_delivery')}
                    </Button>
                  </Popover>
                </div>
              </TableCell>

              <TableCell>{value.places}</TableCell>
              <TableCell>{value.weightKg}</TableCell>
              <TableCell>{value.volumeM3}</TableCell>
              <TableCell>{value.volumeKg}</TableCell>
              <TableCell>{value.amount}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  className={classes.button}
                  color="default"
                  onClick={onShipmentDateClick.bind(null, value)}
                  size="small"
                >
                  <img src={calendar_icon} className={classes.icon} />
                  <Typography variant="body1" style={{ margin: '0 5px' }}>
                    |
                  </Typography>
                  {value.shipmentDate
                    ? moment(value.shipmentDate).format(APP_DATE_FORMAT)
                    : t('choose_date')}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const styles = (theme) => {
  const colors = theme.palette.custom.shipmentsPage;
  return {
    table: {
      borderRadius: '10px',
    },
    tableContainer: {
      margin: '10px auto 0 auto',
      width: '90%',
      boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.10)',
      border: '1px solid #00000040',
      borderRadius: '10px',
    },
    selectedRow: {
      // backgroundColor: 'rgba(0, 122, 255, 0.25) !important',
      borderLeft: '2px solid #007AFF',
      cursor: 'initial',
    },
    rowHead: {
      backgroundColor: '#28282880',
      '&>*': {
        color: '#FFFFFF',
        textTransform: 'capitalize',
      },
    },
    row: {
      cursor: 'pointer',
    },
    button: {
      textTransform: 'none',
      backgroundColor: '#F2F2F7',
      borderRadius: '10px',
    },
    popover: {
      width: 'auto',
      minWidth: '130px',
      maxWidth: '200px',
      boxShadow: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'auto',
      '& > *': {
        '&.MuiPopover-paper': {
          padding: '7px 10px 7px 10px',
          boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.15)',
        },
      },
    },

    popoverButton: {
      ...theme.button.transparent,
      height: '25px',
      fontWeight: '400',
      marginTop: '5px',
      minWidth: '116px',
    },
    icon: {
      height: '14px',
      width: '14px',
    },
  };
};

export default withStyles(styles)(ShipmentsTotalTable);

import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  pageContent: {
    flexDirection: 'column',
    //paddingBottom: theme.spacing(1),
    flex: '1 0 0px',
    margin: '0 auto',
    // overflowY: 'hidden',

    display: 'flex',
    minWidth: '414px',
    width: '100%',
    maxWidth: '100%',
  },

  customScrollbar: {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#e3e3e9',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
});

const PageContent = ({ classes, children, className }) => {
  return (
    <div
      className={classNames(
        classes.pageContent,
        classes.customScrollbar,
        className
      )}
    >
      {children}
    </div>
  );
};

export default withStyles(styles)(PageContent);

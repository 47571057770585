import http from './http';

export const orders = async () => {
  const result = await http.get('/orders');
  return result.data;
};

export const readyBoxes = async (delivery) => {
  const result = await http.get('/orders/ready/boxes', {
    params: { delivery },
  });
  return result.data;
};

export const readyItems = async (boxId) => {
  const result = await http.get('/orders/ready/items', {
    params: {
      boxId,
    },
  });
  return result.data;
};

export const readyTotals = async () => {
  try {
    const result = await http.get('/orders/ready/totals');
    return result.data;
  } catch (error) {
    console.log('Error getting ready totals:', error.response);
  }
};

export const states = async (query) => {
  const result = await http.get('/orders/states', {
    params: query,
  });
  return result.data;
};

export const changeDelivery = async (orderIds, deliveryId) => {
  const result = await http.patch(`/orders/delivery`, {
    body: {
      orderIds: orderIds,
    },
    params: {
      deliveryId: deliveryId,
    },
  });
  return result.data;
};

export const sendRefusals = async (orderIds) => {
  const result = await http.patch(`/orders/refuse`, {
    body: {
      orderIds: orderIds,
    },
  });
  return result.data;
};

import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
} from '@material-ui/core';
import SelectTopUpMethodDialog from './SelectTopUpMethodDialog';
import BalanceTopupDialog from './BalanceTopupDialog';

import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import card_icon from '../assets/image/card_icon.png';
import bag_icon from '../assets/image/bag_icon.png';
import delivery_icon_black from '../assets/image/delivery_icon_black.png';
import dollar_icon_black from '../assets/image/dollar_icon_black.png';
import cart_icon_black from '../assets/image/cart_icon.png';
import currency_icon from '../assets/image/currency_icon.png';

const BasketTotalTable = ({ classes, data, orderAction }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [onlineTopupOpen, setOnlineTopupOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnlineTopup = () => {
    setOpen(false);

    if (data && data.topupReference /* === 'TEST'*/) setOnlineTopupOpen(true);
  };

  const handleOnlineTopupClose = () => {
    setOnlineTopupOpen(false);
  };

  const currency = data.currency ? data.currency : '';

  const tableData = [
    {
      header: t('balance_with_currency', { currency }),
      value: data.balance,
      displayButton: true,
      buttonText: t('topup'),
      buttonAction: handleClickOpen,
      icon: card_icon,
    },
    {
      header: t('to_order'),
      value: data.readyForOrder,
      displayButton: false,
      buttonText: t('proceed'),
      buttonAction: orderAction,
      icon: currency_icon,
    },
    {
      header: t('available_for_order'),
      value: data.availForOrder,
      displayButton: false,
      buttonText: t('proceed'),
      buttonAction: () => {
        history.push('/orders');
      },
      icon: cart_icon_black,
    },
    {
      header: t('pay_for_order'),
      value: data.topupForOrder,
      displayButton: true,
      buttonText: t('proceed'),
      buttonAction: handleClickOpen,
      icon: dollar_icon_black,
    },
    {
      header: t('items'),
      value: data.itemsForOrder,
      displayButton: false,
      buttonText: t('proceed'),
      buttonAction: orderAction,
      icon: delivery_icon_black,
    },
    {
      header: t('weight_kg'),
      value: data.weightForOrder,
      displayButton: false,
      buttonText: t('proceed'),
      buttonAction: orderAction,
      icon: bag_icon,
    },
  ];

  return (
    <Grid container spacing={2} className={classes.container}>
      {tableData.map((column, index) => (
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          lg={2}
          key={index}
          className={classes.col}
        >
          <Card className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="center">
                <img src={column.icon} className={classes.icon} />
                <Typography
                  variant="h6"
                  component="div"
                  classes={{ h6: classes.cardTitle }}
                >
                  {column.header}
                </Typography>
              </Box>
              <Typography variant="body2" className={classes.cardContent}>
                {column.value}
              </Typography>
            </CardContent>
            <CardActions className={classes.actions}>
              {column.displayButton ? (
                <Button
                  size="small"
                  className={classes.actionButton}
                  onClick={column.buttonAction}
                >
                  {column.buttonText}
                </Button>
              ) : (
                <Button
                  disabled
                  size="small"
                  className={classes.actionButtonNull}
                ></Button>
              )}
            </CardActions>
          </Card>
        </Grid>
      ))}

      <SelectTopUpMethodDialog
        open={open}
        handleClose={handleClose}
        handleOnlineTopup={handleOnlineTopup}
        classes={classes}
      ></SelectTopUpMethodDialog>

      <BalanceTopupDialog
        open={onlineTopupOpen}
        onClose={handleOnlineTopupClose}
        topupId={data.topupId}
        classes={classes}
      />
    </Grid>
  );
};

const styles = (theme) => ({
  table: {
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
  },
  col: {
    maxWidth: '200px',
    //maxHeight: '185px',
  },
  container: {
    marginTop: '1rem',
    display: 'flex',
    flexBasis: '95%',
    justifyContent: 'center',
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '95%',
      gap: '0px',
    },
    [theme.breakpoints.up('xl')]: {
      justifyContent: 'space-around',
    },
  },
  card: {
    padding: '1px',
    boxShadow: 'none',
    height: '100%',
    backgroundColor: '#F2F2F7',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardContent: {
    fontWeight: 500,
    marginTop: '2px',
    fontSize: '20px',
  },
  cardTitle: {
    fontWeight: 500,
    fontSize: '14px',
    whiteSpace: '',
    overflow: 'hidden',
  },

  actionButton: {
    ...theme.button.transparent,
    width: '95%',
    height: '32px',
  },
  actionButtonNull: {
    width: '95%',
    height: '32px',
  },
  dialogButton: {
    ...theme.button.transparent,
  },
  dialogTitle: {
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    height: '18px',
    width: '18px',
    marginRight: '10px',
  },
});

export default withStyles(styles)(BasketTotalTable);

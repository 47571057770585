import {
  Dialog,
  DialogContent,
  TextField,
  withStyles,
  MenuItem,
  Button,
  Grid,
  Typography,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { deliveries } from '../api/delivery-api';
import delivery_icon from '../assets/image/delivery_icon.png';

function ChangeDeliveryDialog({
  classes,
  open,
  onClose,
  itemsNumber,
  onDeliveryChange,
  isLoading,
}) {
  const { t } = useTranslation();
  const [deliveryTariffList, setDeliveryTariffList] = useState([]);
  const [delivery, setDelivery] = useState(undefined);
  useEffect(() => {
    const fetchData = async () => {
      const result = await deliveries(true, null);
      setDeliveryTariffList(result);
    };
    fetchData();
  }, []);

  const hadleChangeDelivery = async () => {
    onDeliveryChange?.(delivery);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="change-delivery-dialog"
    >
      <DialogContent className={classes.dialog}>
        <Grid item container style={{ padding: '20px' }}>
          <Grid item md={12}>
            <Typography variant="h5" className={classes.searchHeading}>
              {t('change_delivery')}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="body1">
              {t('change_delivery_text', { number: itemsNumber })}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <TextField
              classes={{ root: classes.selectField }}
              items={deliveryTariffList}
              name="tariff"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={delivery_icon} className={classes.icon} /> |
                  </InputAdornment>
                ),
              }}
              fullWidth
              select
              value={delivery}
              onChange={(e) => {
                setDelivery(e.target.value);
              }}
              icon={delivery_icon}
            >
              {deliveryTariffList.map((item) => (
                <MenuItem key={item.value} value={item}>
                  {item.text}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} className={classes.buttonContainer}>
            <span>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={onClose}
              >
                {t('cancel')}
              </Button>

              <Button
                disabled={!delivery || isLoading}
                className={classes.button}
                variant="contained"
                color="default"
                onClick={hadleChangeDelivery}
              >
                {t('submit')}
              </Button>
            </span>
            {isLoading && <CircularProgress></CircularProgress>}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

const styles = (theme) => {
  const colors = theme.palette.custom.signUpDialog;

  return {
    formContainer: {
      padding: '30px',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      maxWidth: '715px',
      margin: '0 auto 0 30px',
      [theme.breakpoints.down('lg')]: {
        margin: '0 auto',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '370px',
      },
      [theme.breakpoints.up('xl')]: {
        margin: '0 auto',
      },
    },
    searchHeading: {
      maxWidth: '350px',
      fontWeight: '500',
      fontSize: '22px',
      paddingBottom: '12px',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '2px',
      },
    },
    dialog: {
      backgroundColor: '#F2F2F7',
    },
    button: {
      ...theme.button.filled,
      minWidth: '85px',
      marginRight: '10px',
      '& > *': {
        fontWeight: '500',
        fontSize: '12px',
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px',
    },
    textField: {
      '& > *': {
        borderRadius: '10px',
        height: '40px',
        width: '280px',
        backgroundColor: '#ffffff',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
    addressField: {
      '& > *': {
        borderRadius: '10px',
        height: '64px',
        width: 'clamp(280px, 100%, 600px)',
        backgroundColor: '#ffffff',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
    textLabel: {
      fontSize: '14px',
      marginBottom: '5px',
      marginTop: '30px',
    },
    selectField: {
      borderRadius: '10px',
      backgroundColor: '#fff',
      width: 'clamp(265px, 100%, 900px)',
      height: '32px',
    },
    disabledField: {
      opacity: '0.3',
    },
    nameContainer: {
      display: 'flex',
    },
    nameTextField: {
      marginTop: 0,
      backgroundColor: colors.textField.background,
      '&:first-child': {
        marginRight: theme.spacing(1),
      },
    },

    errorChip: {
      color: colors.errorChip.color,
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(1) * 2,
    },
    icon: {
      width: '14px',
      marginTop: '3px',
      marginRight: '5px',
      marginLeft: '10px',
    },
  };
};

export default withStyles(styles)(ChangeDeliveryDialog);

import React from 'react';
import {
  withStyles,
  Grid,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { useTranslation, Trans } from 'react-i18next';

export const SearchHelpDialog = ({ classes, closeAction }) => {
  const { t } = useTranslation();
  return (
    <Grid container className={classes.root}>
      <Grid xs={12} className={classes.closeIconContainer}>
        <IconButton
          onClick={closeAction}
          style={{ padding: '0', color: '#000' }}
        >
          <CancelIcon fontSize="medium" />
        </IconButton>
      </Grid>
      <Grid item xs={11}>
        <Typography variant="subtitle1" className={classes.text}>
          <b>{t('percentageByFilePart1')}</b>
          {t('percentageByFilePart2')}
        </Typography>
      </Grid>

      <Grid item xs={11}>
        <Typography variant="subtitle1" className={classes.subText}>
          <ol>
            <li>{t('downloadExampleTemplate')}</li>
            <li>
              {t('fillAndSaveFile')}
              <ol style={{ listStyleType: 'lower-alpha' }}>
                <li>{t('matchSheetColumnNames')}</li>
                <li>
                  <Trans
                    i18nKey="mandatoryColumnPartNumber"
                    components={{ 1: <b /> }}
                  />
                </li>
              </ol>
            </li>
            <li>
              <Trans i18nKey="specifyDeliveryTime" components={{ 1: <b /> }} />
            </li>
            <li>{t('uploadFile')} </li>
            <li>{t('seeListQuote')} </li>
            <li>
              <Trans
                i18nKey="enterQuantitiesAddToCart"
                components={{ 1: <b /> }}
              />
            </li>
          </ol>
        </Typography>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => {
  return {
    root: {
      width: '85%',
      margin: '10px auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F2F2F7',
      borderRadius: '20px',
      padding: '10px',
    },

    text: {
      fontSize: '18px',
    },
    subText: {
      fontSize: '16px',
      textAlign: 'left',
      maxWidth: '1180px',
    },
    closeIconContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  };
};

export default withStyles(styles)(SearchHelpDialog);

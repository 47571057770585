import React from 'react';
import classNames from 'classnames';
import {
  withStyles,
  OutlinedInput,
  Typography,
  Button,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

import { useTranslation } from 'react-i18next';

const SearchInput = ({ classes, className, value, onChange, onSearch }) => {
  const { t } = useTranslation();
  function handleChange(event) {
    const { value } = event.target;
    onChange && onChange(value);
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') handleSearchClick();
  }

  function handleSearchClick() {
    onSearch && onSearch();
  }

  return (
    <OutlinedInput
      className={classNames(classes.input, className)}
      classes={{
        adornedStart: classes.adStart,
        notchedOutline: classes.notchedOutline,
        input: classes.text,
      }}
      margin="none"
      autoComplete="on"
      value={value}
      placeholder={t('search_input_placeholder')}
      onChange={handleChange}
      onFocus={(event) => event.target.select()}
      onKeyPress={handleKeyPress}
      startAdornment={
        <InputAdornment position="start">
          <IconButton onClick={handleSearchClick}>
            <SearchOutlinedIcon
              fontSize="small"
              style={{ color: '#000', fontSize: '16px', marginTop: '2px' }}
            />
          </IconButton>
          <Typography style={{ color: '#000' }}>|</Typography>
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="start">
          <Button
            variant="contained"
            color="default"
            className={`${classes.button} ${classes.register}`}
            onClick={handleSearchClick}
            size={'small'}
          >
            {t('search')}
          </Button>
        </InputAdornment>
      }
    />
  );
};

const styles = (theme) => {
  const { backgroundColor } = theme.palette.custom.searchInput;
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '2px 4px',
    },
    input: {
      color: '#00000040',
      width: '265px',
      height: '32px',
      fontWeight: 500,
      borderRadius: '10px',
      backgroundColor,
    },
    text: {
      '&:focus': {
        color: '#000000',
      },
    },
    button: {
      textTransform: 'none',
      fontSize: '12px',
      minWidth: '70px',
      height: '22px',
    },
    notchedOutline: {
      border: 'none',
    },
    register: {
      background: 'rgba(0, 122, 255, 0.15)',
      borderRadius: '8px',
      color: '#007AFF',
    },
    adStart: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  };
};

export default withStyles(styles)(SearchInput);

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      //Page Tabs
      search: 'Search',
      cart: 'Cart',
      orders: 'Orders',
      dispatch: 'Dispatch',
      balance: 'Balance',
      login: 'Login',
      register: 'SignUp',
      logout: 'LogOut',
      manuals: 'Instructions',
      offers: 'Offers',
      api: 'Integration',
      quotation: 'Quotation',
      fill_required: 'Fill required field',
      fill_latin: 'Use Latin characters and digits only',
      man_orders: 'Orders tracking',

      //footer
      terms_and_conditions: 'Terms and Conditions',
      contacts: 'Contacts',
      contact_us: 'Contact Us',
      home: 'Home',
      about_us: 'About Us',
      privacy_policy: 'Privacy Policy',
      follow_us_by: 'Follow us by',

      //header
      profile: 'Profile',
      page: 'Page',

      // User data fields
      user_name: 'NAME',
      user_country: 'COUNTRY',
      user_zip: 'POST CODE',
      user_state: 'STATE/PROVINCE',
      user_city: 'CITY',
      user_street: 'STREET',
      user_building: 'BUILDING',
      user_flat: 'FLAT/OFFICE',
      user_phone: 'PHONE',
      user_email: 'EMAIL',
      user_tax: 'TAX NUMBER',
      user_currency: 'CURRENCY',
      user_contact_person: 'CONTACT PERSON',
      save: 'Save',

      //Home page
      hero_text_heading: 'B2B automotive parts marketplace',
      hero_text_subheading: 'Search for products, buy and sell worldwide',
      hero_register: 'Register',
      how_it_works: 'How it works?',
      how_to_start: 'How to start?',
      card_search_parts: 'Search for car parts',
      card_search_parts_text:
        'You can search for items by number or details, compare items and choose the most suitable item.',
      place_order: 'Place an order',
      place_order_text:
        'Once you have selected the appropriate items, you can specify the type and destination of delivery and order the goods in a couple of clicks.',
      track_order: 'Order tracking',
      track_order_text: 'You can track the progress of your orders.',
      what_can_it_do: 'What can All Car Parts do?',
      each_option_better: 'Every parts helps to work more efficiently',
      main_advantage: 'Main advantage',
      data_in_the_palm: 'All the data in the palm of your hand',
      brief_about_us: 'Briefly about us',
      about_us_text1:
        'AllCarParts DWC-LLC specializes in the wholesale supply of OEM and Aftermarket auto parts and spare parts for trucks and construction equipment.',
      about_us_text2:
        'We offer a wide range of car parts from all manufacturers including Toyota, Mitsubishi, Honda, Nissan, Mazda, Suzuki, Ford, Kia, Hyundai, Subaru, Mercedes, Lexus, Rover, Chevrolet, BMW, Isuzu, Renault, Daewoo, Land Rover, Daihatsu and many more.',
      watch_our_promo: 'Watch our promo',

      //Home page cards:
      card1Text: 'Flexible auto parts search',
      card1Action: 'Jump to search',
      card2Text: 'Ready-made price lists for car parts',
      card2Action: 'View price lists',
      card3Text: 'Quality control for each product',
      card4Text:
        'Customers from 47 countries are already shopping on our website',
      card4Action: 'Become a partner',
      card5Text: 'Prompt and efficient delivery anywhere in the world',
      card5Action: 'Try it out',
      card6Text:
        'A large selection of high-quality products from our suppliers.',
      hugeCard1Title: 'Our team',
      hugeCard1Text: `Each member of our team is an expert in their field and has extensive experience in the automotive industry. We are here for you on a 24/7 basis.
                      You can contact us by email or telephone. We are committed to providing our customers with the best possible experience.`,
      hugeCard2Title: 'Location',
      hugeCard2Text: `Our company is strategically located in one of Dubai's free economic zones.
                      This location enables us to deliver quickly and tax-free.`,
      hugeCard3Title: 'Buy or sell',
      hugeCard3Text: `We offer the best prices and invite cooperation from both buyers and suppliers. If you are interested in buying or selling auto parts, please write to us.
                      Our representative will contact you within 24 hours of receiving your email.`,

      form_header: 'Application form',
      form_section_header:
        'Do you have any questions or want to become our partner?',
      form_section_subheader:
        'Leave a request, our managers will contact you during office hours',
      form_field_name: 'Enter your name',
      form_field_email: 'Enter your email',
      formTab_contact_manager: 'Contact the manager',
      formTab_become_partner: 'Become a partner',
      form_button: 'Send',
      form_data_not_processed:
        'Your data is not processed and is only used to contact you!',
      office_address: 'Office address',

      //Timeline Home page
      item1Heaging: 'Online registration',
      item2Heaging: 'Activating your account',
      item3Heaging: 'Search for car parts',
      item4Heaging: 'Order placement and payment',
      item5Heaging: "That's it!",
      itemManualHeading: 'Still have questions?',
      item2Content: `
                    In order to use all the features of our platform and to place orders, you need to top up your account for <b>$500</b>.
                    <br><br> You can make a deposit at the end of registration or later via your personal account.`,

      item3Content: `We have two convenient ways of searching: by part number and by uploading a file for a group search.
                    <br><br> You can quickly search for specific items and specify delivery date, type and destination.
                      `,
      item4Content: `Once you have found the right parts, specified the time and type of delivery, add these items to your shopping basket.
                    <br><br> Top up 15% of the total order value to complete the order. You will then have the opportunity to order the goods.`,
      item5Content: `You can now track the status of your order and all order and transaction history is stored with you.`,

      itemManualContent: 'Check out our <1>manuals</1>',
      itemManualContent2: `You will find step-by-step instructions on how to work with each part of our platform: Registering and logging into your account,
                           searching, filtering products and offers, placing an order, tracking existing orders, and adding destinations.`,

      //news
      news: 'News',
      news_section: 'News section',
      news_title: 'Stay tuned for world news and platform updates',
      jump_to_news: 'Read',
      check_all_news: 'Check all news',
      other_news: 'Other news',
      author: 'Author',
      share: 'Share',
      new_news: 'Hello! We have some urgent news for you.',

      // Search page
      by_part_number: 'By Part Number',
      by_excel_file: 'By Excel File',
      to_cart: 'To Cart',
      export: 'Export',
      upload: 'Upload',
      upload_csv_file: 'Upload Excel File',
      max_days: 'Select the deadline for delivery',
      template: 'Template example',
      demo_prices_log_in:
        'You see demo prices and limited offers. Please login',
      demo_prices_complete_registration:
        'You see demo prices and limited offers. Please deposit USD500 to complete registration',
      for_order: 'To Order',
      error_quotation: 'Cannot process quotation due to error',
      quotation_differs:
        'Some items you can purchase with new lead times, prices or quantities',
      where_to_dispatch: 'Delivery',
      choose_search: 'Choose search method',
      provide_part_number: 'Provide part number',
      choose_delivery_method: 'Choose delivery method',
      choose_currency: 'Choose currency',
      nothing_found: 'Nothing was found for the query "{{item}}"',
      no_results_found:
        'Nothing was found for your request, please try to upload another file and make sure your file is filled in according to the template. To do this, click',
      here: 'here',
      add_delivery_method: 'Add your destination',
      load_all_items: 'Load all the offers',
      collapse: 'Collapse',

      //searh help dialog
      percentageByFilePart1: 'Quotation by file',
      percentageByFilePart2: ' - search by your list of auto parts numbers.',
      downloadExampleTemplate: 'Download the example template for filling out.',
      fillAndSaveFile:
        'Fill out and save the Excel file in the predetermined format with the numbers you want to evaluate.',
      matchSheetColumnNames:
        'Be sure to match the template sheet and column names.',
      mandatoryColumnPartNumber:
        'The only mandatory column to fill out is partnumber, all other columns are optional (filling out optional columns will only clarify your requirements for the search).',
      specifyDeliveryTime:
        'Specify your delivery time constraint (to our warehouse) in the field Select the readiness term for delivery',
      uploadFile: 'Upload the file.',
      seeListQuote: 'You will see the quote for your list on the screen.',
      enterQuantitiesAddToCart:
        'You can enter quantities for the positions that interest you and click the Add to Cart button to move the selected positions to the cart for ordering, or click the Export button to download the pricing results as an Excel file.',

      //delivery dialog
      edit_delivery_heading: 'Edit delivery',
      select_destination: 'Select destination',
      delivery_days:
        'Specify shipping days or "On request" for on-demand shipments',
      choose_carrier: 'Choose carrier',
      min_weight: 'Enter minimum weight in kg',
      destination_active: 'Destination is active',
      no_carrier: 'No carrier in the list? <1>Write to us</1',

      // Balance page
      cannot_export_invoice: 'Cannot export invoice due to the error',
      start_date: 'Start Date',
      end_date: 'End Date',
      show: 'Show',
      transaction_history: 'Transaction history',
      specify_period: 'Specify the period',
      topup_online: 'TopUp Online',
      bank_transfer: 'Bank Transfer',
      document: 'document',
      debit: 'Debit',
      credit: 'Credit',

      // Basket page
      cannot_select_item: 'Cannot select item(s) due to the error',
      cannot_change_quantity: 'Cannot change quantity due to the error',
      cannot_apply_changes: 'Cannot apply changes due to the error',
      cannot_order_selected: 'Cannot order selected due to the error',
      cannot_delete_selected: 'Cannot delete selected due to the error',
      order: 'Order',
      remove: 'Remove',
      order_accepted:
        'Your order № {{orderId}} in amount of {{currency}}{{amount}} is accepted.',
      order_not_accepted:
        'Your order not accepted due to the error (code = {{orderId}})',
      activate_account: 'Please contact us to activate your account',
      insufficient_funds: 'Insufficient funds, please check your balance',
      no_items: 'No items for order',
      basket_log_in: 'To place orders, kindly login',
      basket_complete_registration:
        'To place orders, kindly deposit USD500 to complete registration',
      proceed: 'Proceed',

      //Select topUp dialog
      choose_payment_method: 'Choose payment method',
      online_payment_method: 'Online payment',
      bank_transfer_payment_method: 'Bank transfer',

      // Orders page
      part_number: 'Part number',
      order_number: 'Order number',
      reference: 'Reference',
      all: 'all',
      change_delivery: 'Change delivery',
      change_delivery_text: 'Changing delivery for {{number}} item(s)',
      provide_order_number: 'Enter order number',
      deliveryChangeErrors:
        'There were errors changing the delivery of some items.',
      provider_reference: 'Enter reference',
      send_refusal: 'Refuse',
      refusal_indicator: 'Refusal accepted',
      refuseDialog: {
        title: 'Are you sure you want to refuse the selected orders?',
        cancel: 'Cancel',
        confirm: 'Confirm',
      },

      // add delivery dialog
      addingShippingMethod: 'Adding a shipping method',
      selectDestination: 'Select the destination',
      specifyShippingDays:
        "Specify shipping days or 'on request' for on-demand shipping",
      selectCarrier: 'Select the carrier',
      carrierNotInList: 'Carrier not in the list?',
      contactUs: 'Contact us',
      enterMinimumWeight: 'Enter the minimum weight in kg',
      destinationActive: 'Destination is active',

      // Password reset page
      submit: 'Submit',
      new_password: 'New password',

      // Register page
      provide_registration_data_text:
        'Enter registration data for further login',
      register_step1: 'Login data',
      register_step2: 'Personal Data',
      register_step3: 'Account Activation',
      register_step4: 'Registration completed',
      header_have_account: 'Already have an account?',
      next_step: 'Next step',
      prev_step: 'Back',
      skip: 'Skip',
      not_selected: 'Not selected',
      san_francisco: 'San Francisco',
      california: 'California',
      contact_person: 'Andrei',
      fill_later: 'You can skip this step and fill out this form later.',
      top_up_later: 'You can skip this step and top up your wallet later.',
      activation_sub_title1:
        'By activating your account, you can create orders and download <1>price lists</1>.',
      activation_sub_title2:
        'To activate your account, you need to top up your account for <1>$500</1>',
      activation_sub_title4:
        'If you have any questions, you can contact us at <1>sales@allcarparts.ae</1>',
      password_requiremests: 'Password does not meet the requirements',
      password_requiremests_head: 'Password Requirements:',
      password_at_least_chars: '- at least 8 characters',
      password_at_least_special: '- at least 1 special character',
      password_at_least_digit: '- at least 1 digit',
      header_step: 'Step',

      //Profile page
      old_password: 'Old password',
      acc_data: 'Account data',
      personal_data: 'Personal data',

      //Log in page
      log_in_head: 'Log in',
      reset_password: 'Reset password',
      reset: 'Reset',
      register_button: 'Create account',
      header_log_in: 'Log in',
      header_first_time_here: 'First time here?',

      // Shipments page
      cannot_update_shipment_date:
        'Cannot update shipment date due to the error',
      edit_delivery: 'Edit',

      // Balance topup dialog
      amount_with_currency: 'Amount ({{currency}})',
      charge: 'CHARGE: {{percent}}% + {{fixed}} {{currency}}',
      topup: 'Top up',

      // Balance total table
      balance_with_currency: 'Balance ({{currency}})',
      in_orders: 'In Orders',
      in_cart: 'In Cart',
      pay_for_cart: 'Pay for Cart',
      ready_to_ship: 'Ready to Ship',
      pay_to_ship: 'Pay to Ship',

      // Basket table
      brand: 'Brand',
      price_with_currency: 'Price ({{currency}})',
      quantity: 'Quantity',
      total_with_currency: 'Total ({{currency}})',
      weight_kg: 'Weight (kg)',
      booking: 'Booking',
      delivery: 'Delivery',
      description: 'Description',
      duplication: 'Duplication',

      // Basket total table
      to_order: 'To Order',
      available_for_order: 'Available for Order',
      pay_for_order: 'Pay for Order',
      items: 'Items',

      // Captcha
      captcha_field_placeholder: 'ENTER TEXT FROM AN IMAGE ABOVE',

      //Catalog page
      offers_log_in: 'To download offers, kindly login',
      offers_complete_registration:
        'To download offers, kindly deposit USD500 to complete registration',
      delivery_ready_term: 'Select a readiness time for delivery',
      enter_parts_brand: 'Enter part brands',

      // Catalog table
      offer: 'Offer',
      download: 'Download',
      lines: 'Lines',
      updated: 'Updated',

      //Email verified page
      email_verified: 'Your email has been verified',
      cancel: 'Cancel',

      // Log In dialog
      email: 'email',
      password: 'Password',
      forgot_password: 'Forgot password?',
      reset_link_sent: 'Reset link is sent to your email',
      log_in: 'LOG IN',
      error_login: 'Login and/or password are incorrect',

      // Orders table
      date: 'Date',
      order_price: 'Order Price',
      sale_price: 'Sale Price',
      ordered: 'Ordered',
      purchased: 'Purchased',
      shipped: 'Shipped',
      refused: 'Refused',
      state_date: 'State Date',
      invoice: 'Shipment',

      // Search input
      search_input_placeholder: 'Search...',

      // Search table
      substituted: 'Substituted',
      days: 'Days',
      available: 'Available',
      volume_kg: 'Volume (kg)',
      comment: 'Comment',
      n_a: 'N/A',

      // Shipment date dialog
      enabled: 'Enabled',

      // Shipment box table
      row_id: 'Row ID',

      // Shipment table
      place: 'place',
      length_m: 'Length (m)',
      height_m: 'Height (m)',
      width_m: 'Width (m)',
      value_with_currency: 'Value ({{currency}})',

      // Shipment total table
      places: 'Places',
      volume_m3: 'Volume (m³)',
      schedule: 'Schedule',
      choose_date: 'Choose date',

      // Sign up dialog
      company_name: 'Company name',
      contact_phone: 'Contact phone',
      confirm_password: 'Confirm password',
      sign_up: 'sign up',
      error_password_not_match: 'Passwords do not match',

      //contact page
      warehouse_location: 'Warehouse location',

      //Manuals page
      tab_register: 'register',
      tab_quotations: 'make quotations',
      tab_offers: 'download offers',
      tab_api: 'use API',

      //TopUp Dialog
      error_amount: 'Incorrect amount',
      error_something: 'Something went wrong, contact us',
    },
  },
  uk: {
    translation: {
      //Page Tabs
      search: 'Пошук',
      cart: 'Кошик',
      orders: 'Замовлення',
      dispatch: 'Відправлення',
      balance: 'Баланс',
      login: 'Вхід до системи',
      register: 'Реєстрація',
      logout: 'Вихід із системи',
      manuals: 'Інструкції',
      offers: 'Пропозиції',
      api: 'Інтеграція',
      quotation: 'Пропозиція ціни',
      fill_required: 'Заповніть необхідне поле',
      fill_latin: 'Використовуйте лише латинські літери та цифри',
      man_orders: 'Відслідковування замовлень',

      //footer
      terms_and_conditions: 'Положення та умови',
      contacts: 'Контакти',
      contact_us: "Зв'яжіться з нами",
      home: 'Головна сторінка',
      about_us: 'Про нас',
      privacy_policy: 'Політика конфіденційності',
      follow_us_by: 'Слідкуйте за нами',

      //header
      profile: 'Профіль',
      page: 'Сторінка',

      // User data fields
      user_name: "ІМ'Я",
      user_country: 'КРАЇНА',
      user_zip: 'ПОШТОВИЙ ІНДЕКС',
      user_state: 'ШТАТ/ПРОВІНЦІЯ',
      user_city: 'МІСТО',
      user_street: 'ВУЛИЦЯ',
      user_building: 'БУДИНОК',
      user_flat: 'КВАРТИРА/ОФІС',
      user_phone: 'ТЕЛЕФОН',
      user_email: 'АДРЕСА ЕЛЕКТРОННОЇ ПОШТИ',
      user_tax: 'НОМЕР ПЛАТНИКА ПОДАТКІВ',
      user_currency: 'ВАЛЮТА',
      user_contact_person: 'КОНТАКТНА ОСОБА',
      save: 'Зберегти',

      //Home page
      hero_text_heading: 'B2B маркетплейс автомобільних запчастин',
      hero_text_subheading:
        'Шукайте товари, купляйте та продавайте у всьому світі',
      hero_register: 'Зареєструватись',
      how_it_works: 'Як це працює?',
      how_to_start: 'Як розпочати?',
      card_search_parts: 'Пошук автомобільних запчастин',
      card_search_parts_text:
        'Ви можете шукати запасні частини за номером або деталі, порівнювати їх та обирати найбільш відповідні.',
      place_order: 'Розмістіть замовлення',
      place_order_text:
        'Щойно Ви виберете відповідні запасні частини, Ви можете вказати тип і місце доставки та замовити товари в пару кліків.',
      track_order: 'Відслідковування замовлень',
      track_order_text: 'Ви можете відслідковувати виконання Ваших замовлень.',
      what_can_it_do: 'Чим займається компанія All Car Parts?',
      each_option_better:
        'Кожна запасна частина допомагає підвищити ефективність роботи',
      main_advantage: 'Головна перевага',
      data_in_the_palm: 'Усі дані на Вашій долоні',
      brief_about_us: 'Коротко про нас',
      about_us_text1:
        'Компанія AllCarParts DWC-LLC спеціалізується на оптових поставках оригінальних запасних частин та автозапчастин вторинного ринку, а також запчастин для вантажівок і будівельного обладнання.',
      about_us_text2:
        'Ми пропонуємо широкий асортимент запчастин від усіх виробників, включно з компаніями Toyota, Mitsubishi, Honda, Nissan, Mazda, Suzuki, Ford, Kia, Hyundai, Subaru, Mercedes, Lexus, Rover, Chevrolet, BMW, Isuzu, Renault, Daewoo, Land Rover, Daihatsu та багатьма іншими.',
      watch_our_promo: 'Передивіться наш рекламний ролик',

      //Home page cards:
      card1Text: 'Пошук автомобільних запчастин із гнучкими опціями',
      card1Action: 'Перейти до пошуку',
      card2Text: 'Шаблонні рахунки-фактури для автомобільних запчастин',
      card2Action: 'Переглянути рахунки-фактури',
      card3Text: 'Контроль якості кожного виробу',

      card4Text:
        'На нашому веб-сайті вже купляють продукцію клієнти з 47 країн',
      card4Action: 'Станьте нашим партнером',
      card5Text: 'Швидкі та оперативні поставки у будь-яку точку світу',
      card5Action: 'Перевірте це на досвіді',
      card6Text:
        'Великий вибір високоякісних виробів від наших постачальників.',
      hugeCard1Title: 'Наша команда',
      hugeCard1Text: `Кожен член нашої команди є експертом у своїй сфері та має великий досвід роботи в автомобільній промисловості. Ми надаємо Вам послуги цілодобово.
                      Ви можете зв'язатися з нами електронною поштою або за телефоном. Ми прагнемо, щоб від співпраці з нами у наших клієнтів залишались лише найкращі враження`,
      hugeCard2Title: 'Місце розташування',
      hugeCard2Text: `Наша компанія стратегічно розташована в одній із вільних економічних зон м. Дубай.
                      Таке місцеположення дозволяє нам постачати вироби швидко та без необхідності сплати податків.`,
      hugeCard3Title: 'Купуйте або продавайте',
      hugeCard3Text: `Ми пропонуємо найкращі ціни та запрошуємо до співпраці як покупців, так і постачальників. Якщо Ви зацікавлені в купівлі або продажу автомобільних запчастин, будь ласка, напишіть нам.
                      Наш представник зв'яжеться з Вами упродовж 24 годин із моменту одержання Вашого електронного повідомлення.`,
      form_header: 'Бланк для заявки',
      form_section_header:
        'У Вас є будь-які запитання або Ви бажаєте стати нашим партнером?',
      form_section_subheader:
        "Залиште запит, наші менеджери зв'яжуться з Вами у робочий час",
      form_field_name: "Введіть Ваше ім'я",
      form_field_email: 'Введіть Вашу адресу електронної пошти',
      formTab_contact_manager: "Зв'яжіться з менеджером",
      formTab_become_partner: 'Станьте нашим партнером',
      form_button: 'Відіслати',
      form_data_not_processed:
        "Ваші дані не обробляються та використовуються лише для зв'язку з Вами!",
      office_address: 'Адреса офісу',

      //Timeline Home page
      item1Heaging: 'Онлайн-реєстрація',
      item2Heaging: 'Активація Вашого облікового запису',
      item3Heaging: 'Пошук автомобільних запчастин',
      item4Heaging: 'Розміщення замовлення та сплата за замовленням',
      item5Heaging: 'Ось воно!',
      itemManualHeading: 'Залишились питання?',
      item2Content: `
                    Для того, щоб використовувати всю функціональність нашої платформи та розміщувати замовлення, Вам необхідно поповнити Ваш рахунок на <b>$500</b>.
                    <br><br> Ви можете поповнити Ваш рахунок після реєстрації або пізніше, скориставшись Вашим обліковим записом.`,

      item3Content: `Ми пропонуємо два зручних способи пошуку: за номером запчастини або шляхом вивантаження файлу для групового пошуку.
                    <br><br> Ви можете швидко знайти конкретні запасні частини і вказати дату поставки, тип і пункт призначення.
                      `,
      item4Content: `Щойно Ви знайдете запчастини, які Вам необхідні, вкажіть час і тип доставки, додайте вказані запасні частини до Вашого кошику покупок.
                    <br><br> Для завершення оформлення замовлення поповніть Ваш рахунок на суму, що дорівнює 15% від загальної суми замовлення. Потім Вам буде надана можливість замовити товари`,
      item5Content: `Тепер Ви можете відслідковувати статус свого замовлення; уся Ваша історія замовлень і транзакцій зберігається у Вашому обліковому записі.`,
      itemManualContent: 'Ознайомтеся з нашими <1>посібниками</1>',
      itemManualContent2: `Ви знайдете покрокові інструкції по роботі з кожною частиною нашої платформи: створення і вхід в аккаунт,
                            пошук, фільтрація товарів і пропозицій, оформлення замовлення, відстеження вже існуючих замовлень, додавання направлень.
                            `,

      //news
      news: 'Новини',
      news_section: 'Розділ новин',
      news_title: 'Слідкуйте за світовими новинами та оновленнями платформи',
      jump_to_news: 'Читати',
      check_all_news: 'Перевірити всі новини',
      other_news: 'Інші новини',
      author: 'Автор',
      share: 'Поділитися',
      new_news: 'Вітаємо! У нас є для Вас термінові новини.',

      //Select topUp dialog
      choose_payment_method: 'Оберіть спосіб поповнення',
      online_payment_method: 'Оплата онлайн',
      bank_transfer_payment_method: 'Банківський переказ',

      // Search page
      by_part_number: 'За номером запчастини',
      by_excel_file: 'За файлом Excel',
      to_cart: 'До кошику',
      export: 'Експорт',
      upload: 'Вивантажити',
      upload_csv_file: 'Вивантажити файл Excel',
      max_days: 'Оберіть кінцевий термін поставки',
      template: 'Приклад шаблону',
      demo_prices_log_in:
        'Ви бачите ціни в ДЕМОНСТРАЦІЙНОМУ режимі. Щоб одержати інформацію про реальні ціни, будь ласка, увійдіть до системи',
      demo_prices_complete_registration:
        'Ви бачите ціни в ДЕМОНСТРАЦІЙНОМУ режимі. Щоб одержати інформацію про реальні ціни, будь ласка, поповніть рахунок на 500 доларів США, щоб завершити процес реєстрації',
      for_order: 'Перехід до замовлення',
      error_quotation:
        'Не вдається обробити цінову пропозицію внаслідок помилки',
      quotation_differs:
        'Деякі запасні частини, які Ви можете придбати з новими термінами поставки, цінами або кількістю',
      where_to_dispatch: 'Поставка',
      choose_search: 'Оберіть метод пошуку',
      provide_part_number: 'Вкажіть номер запчастини за каталогом',
      choose_delivery_method: 'Оберіть метод поставки',
      choose_currency: 'Оберіть валюту',
      nothing_found: 'За запитом нічого не знайдено "{{item}}"',
      no_results_found:
        'За вашим запитом нічого не знайдено, будь ласка, спробуйте завантажити інший файл і переконайтеся, що ваш файл заповнений шаблоном. Як це зробити, натисніть',
      here: 'сюди',
      add_delivery_method: 'Додайте інформацію про місце призначення',
      load_all_items: 'Завантажити всі замовлення',
      collapse: 'Згорнути',

      //searh help dialog
      percentageByFilePart1: 'Процінка файлом',
      percentageByFilePart2: ' - пошук за вашим списком номерів автозапчастин.',
      downloadExampleTemplate: 'Завантажте приклад шаблону для заповнення.',
      fillAndSaveFile:
        'Заповніть і збережіть Excel-файл у попередньо визначеному форматі номерами, які ви хочете оцінити.',
      matchSheetColumnNames:
        "Обов'язково дотримуйтесь відповідності шаблону найменувань аркуша і колонок.",
      mandatoryColumnPartNumber:
        "Єдина обов'язкова для заповнення колонка - це partnumber, всі інші колонки є опціональними (заповнення опціональних колонок лише уточнюють ваші вимоги для пошуку).",
      specifyDeliveryTime:
        'Вкажіть ваше обмеження за терміном поставки (до нашого складу) у полі Виберіть термін готовності для доставки',
      uploadFile: 'Завантажте файл.',
      seeListQuote: 'Ви побачите котирування вашого списку на екрані.',
      enterQuantitiesAddToCart:
        'Ви можете вказати кількість для позицій, які вас цікавлять, і натиснути кнопку Додати до кошика, щоб перемістити вибрані позиції до кошика для замовлення, або натиснути кнопку Експорт, щоб завантажити результати оцінки у вигляді Excel-файлу.',

      //delivery dialog
      edit_delivery_heading: 'Редагування способу відвантаження',
      select_destination: 'Виберіть точку призначення',
      delivery_days:
        'Вкажіть дні відвантаження або "за запитом" для відправки за запитом',
      choose_carrier: 'Виберіть перевізника',
      min_weight: 'Введіть мінімальну вагу в кг',
      destination_active: 'Напрямок активний',
      no_carrier: ' Немає перевізника в списку? <1>Напишіть нам</1>',

      // Balance page
      cannot_export_invoice:
        'Не вдається експортувати рахунок-фактуру внаслідок помилки',
      start_date: 'Початкова дата',
      end_date: 'Кінцева дата',
      show: 'Показати',
      transaction_history: 'Історія транзакцій',
      specify_period: 'Вкажіть період',
      topup_online: 'Поповнити онлайн',
      bank_transfer: 'Банківський переказ',
      document: 'документ',
      debit: 'Видатки',
      credit: 'Прибутки',

      // Basket page
      cannot_select_item:
        'Не вдається обрати запасні частини внаслідок помилки',
      cannot_change_quantity: 'Не вдається змінити кількість внаслідок помилки',
      cannot_apply_changes: 'Не вдається застосувати зміни внаслідок помилки',
      cannot_order_selected: 'Не вдається замовити обране внаслідок помилки',
      cannot_delete_selected: 'Не вдається видалити обране внаслідок помилки',
      order: 'Замовити',
      remove: 'Видалити',
      order_accepted:
        'Ваше замовлення №{{orderId}} на суму {{currency}}{{amount}} прийняте',
      order_not_accepted:
        'Ваше замовлення не прийняте внаслідок помилки (код = {{orderId}})',
      activate_account:
        "Будь ласка, зв'яжіться з нами для активації Вашого облікового запису",
      insufficient_funds:
        'Недостатньо коштів, будь ласка, перевірте Ваш баланс',
      no_items: 'Відсутні запасні частини для замовлення',
      basket_log_in:
        'Для розміщення замовлень, будь ласка, увійдіть до системи',
      basket_complete_registration:
        'Для розміщення замовлень, будь ласка, внесіть 500 доларів США для завершення реєстрації',
      proceed: 'Продовжити',

      // Orders page
      part_number: 'Номер запчастини за каталогом',
      order_number: 'Номер замовлення',
      reference: 'Посилання',
      change_delivery: 'Змінити метод доставки',
      change_delivery_text: 'Зміна методу доставки для {{number}} артикулу(ів)',
      send_refusal: 'Відмовитися',
      refusal_indicator: 'Відмову прийнято',
      refuseDialog: {
        title: 'Ви впевнені, що хочете відмовитися від вибраних замовлень?',
        cancel: 'Відміна',
        confirm: 'Підтвердити',
      },
      all: 'Усе',
      provide_order_number: 'Введіть номер замовлення',
      deliveryChangeErrors:
        'Сталися помилки при зміні доставки деяких товарів.',
      provider_reference: 'Введіть посилання',

      // add delivery dialog
      addingShippingMethod: 'Додавання способу відправки',
      selectDestination: 'Виберіть пункт призначення',
      specifyShippingDays:
        "Вкажіть дні відправки або 'на запит' для відправки за запитом",
      selectCarrier: 'Виберіть перевізника',
      carrierNotInList: 'Перевізника немає в списку?',
      contactUs: 'Напишіть нам',
      enterMinimumWeight: 'Введіть мінімальну вагу в кг',
      destinationActive: 'Напрямок активний',

      // Password reset page
      submit: 'Підтвердити',
      new_password: 'Новий пароль',

      // Register page
      provide_registration_data_text:
        'Введіть дані реєстрації для продовження входу до системи',
      register_step1: 'Дані для входу до системи',
      register_step2: 'Персональні дані',
      register_step3: 'Активація облікового запису',
      register_step4: 'Реєстрацію завершено',
      header_have_account: 'Вже маєте обліковий запис?',
      next_step: 'Наступний крок',
      prev_step: 'Назад',
      skip: 'Пропустити',
      not_selected: 'Не обрано',
      san_francisco: 'Сан-Франциско',
      california: 'Каліфорнія',
      contact_person: 'Андрій',
      fill_later: 'Ви можете пропустити цей крок і заповнити форму пізніше.',
      top_up_later:
        'Ви можете пропустити цей крок і поповнити Ваш гаманець пізніше.',
      activation_sub_title1:
        'Після активації Вашого облікового запису Ви можете створювати замовлення та завантажувати <1>рахунки-фактури</1>.',
      activation_sub_title2:
        'Для активації Вашого облікового запису Вам необхідно поповнити Ваш рахунок на суму <1>500 доларів США</1>',
      activation_sub_title4:
        "Якщо у Вас є будь-які запитання, Ви можете зв'язатися з нами за адресою <1>sales@allcarparts.ae</1>",
      password_requiremests: 'Пароль не відповідає необхідним вимогам',
      password_requiremests_head: 'Вимоги до паролю:',
      password_at_least_chars: '– щонайменше 8 символів',
      password_at_least_special: '– щонайменше 1 спеціальний символ',
      password_at_least_digit: '– щонайменше 1 цифра',
      //Profile page
      old_password: 'Старий пароль',
      acc_data: 'Дані облікового запису',
      personal_data: 'Персональні дані',
      header_step: 'Крок',

      //Log in page
      log_in_head: 'Увійти до системи',
      reset_password: 'Скинути пароль',
      reset: 'Скинути',
      register_button: 'Створити обліковий запис',
      header_log_in: 'Увійти до системи',
      header_first_time_here: 'Ви тут вперше?',

      // Shipments page
      cannot_update_shipment_date:
        'Не вдається оновити дані про дату поставки внаслідок помилки',
      edit_delivery: 'Редагувати',

      // Balance topup dialog
      amount_with_currency: 'Кількість ({{currency}})',
      charge: 'СУМА, ЩО СПИСУЄТЬСЯ: {{percent}}% + {{fixed}} {{currency}}',
      topup: 'Поповнити',

      // Balance total table
      balance_with_currency: 'Баланс ({{currency}})',
      in_orders: 'У замовленнях',
      in_cart: 'У кошику',
      pay_for_cart: 'Сплатити за те, що знаходиться у Кошику',
      ready_to_ship: 'Готове до відправки',
      pay_to_ship: 'Сплатити за відправку',

      // Basket table
      brand: 'Торговельна марка',
      price_with_currency: 'Ціна ({{currency}})',
      quantity: 'Кількість',
      total_with_currency: 'Всього ({{currency}})',
      weight_kg: 'Вага (кг)',
      booking: 'Реєстрація замовлення',
      delivery: 'Поставка',
      description: 'Опис',
      duplication: 'Дублювання',

      // Basket total table
      to_order: 'Для замовлення',
      available_for_order: 'Доступно для замовлення',
      pay_for_order: 'Сплатити за замовлення',
      items: 'Запасні частини',

      // Captcha
      captcha_field_placeholder:
        'ВВЕДІТЬ ТЕКСТ ІЗ ЗОБРАЖЕННЯ, ЯКЕ ЗНАХОДИТЬСЯ ВИЩЕ',

      //Catalog page
      offers_log_in:
        'Для завантаження пропозицій, будь ласка, увійдіть до системи',
      offers_complete_registration:
        'Для завантаження пропозицій, будь ласка, внесіть на рахунок 500 доларів США для завершення реєстрації',
      delivery_ready_term: 'Оберіть час готовності до поставки',
      enter_parts_brand: 'Введіть торговельні марки виробників запчастин',

      // Catalog table
      offer: 'Пропозиція',
      download: 'Завантажити',
      lines: 'Рядки',
      updated: 'Оновлено',

      //Email verified page
      email_verified: 'Ваша адреса електронної пошти була перевірена',
      cancel: 'Скасувати',

      // Log In dialog
      email: 'адреса електронної пошти',
      password: 'Пароль',
      forgot_password: 'Забули пароль?',
      reset_link_sent:
        'Посилання для скидання пароля надіслано на Вашу адресу електронної пошти',
      log_in: 'Увійти',
      error_login: 'Невірні дані для входу до системи та/або пароль',

      // Orders table
      date: 'Дата',
      order_price: 'Ціна замовлення',
      sale_price: 'Ціна реалізації',
      ordered: 'Замовлено',
      purchased: 'Придбано',
      shipped: 'Відправлено',
      refused: 'Відхилено',
      state_date: 'Вкажіть дату',
      invoice: 'Відправлення',

      // Search input
      search_input_placeholder: 'Пошук...',

      // Search table
      substituted: 'Замінено',
      days: 'Днів',
      available: 'Доступно',
      volume_kg: "Об'єм (кг)",
      comment: 'Коментар',
      n_a: 'Не застосовно',

      // Shipment date dialog
      enabled: 'Активовано',

      // Shipment box table
      row_id: 'Ідентифікатор рядка',

      // Shipment table
      place: 'місце',
      length_m: 'Довжина (м)',
      height_m: 'Висота (м)',
      width_m: 'Ширина (м)',
      value_with_currency: 'Вартість ({{currency}})',

      // Shipment total table
      places: 'Кількість місць',
      volume_m3: "Об'єм (м³)",
      schedule: 'Графік',
      choose_date: 'Оберіть дату',

      // Sign up dialog
      company_name: 'Назва компанії',
      contact_phone: 'Контактний номер телефона',
      confirm_password: 'Підтвердьте пароль',
      sign_up: 'зареєструватись',
      error_password_not_match: 'Паролі не співпадають',

      //contact page
      warehouse_location: 'Місце розташування складу',

      //Manuals page
      tab_register: 'Реєстрація',
      tab_quotations: 'запропонувати ціни',
      tab_offers: 'завантажити пропозиції',
      tab_api: 'використовувати прикладний програмний інтерфейс',

      //TopUp Dialog
      error_amount: 'Невірна сума',
      error_something: 'Щось пішло не так, зверніться до нас за допомогою',
    },
  },
  ru: {
    translation: {
      //Page Tabs
      search: 'Поиск',
      cart: 'Корзина',
      orders: 'Заказы',
      dispatch: 'Отправка',
      balance: 'Баланс',
      login: 'Войти',
      register: 'Регистрация',
      logout: 'Выйти',
      offers: 'Прайсы',
      manuals: 'Инструкции',
      api: 'Интеграция',
      quotation: 'Котировка',
      fill_required: 'Необходимо заполнить поле',
      fill_latin: 'Используйте только латинские буквы и цифры',
      man_orders: 'Трекинг заказов',

      //footer
      terms_and_conditions: 'Условия работы',
      contacts: 'Контакты',
      contact_us: 'Связаться',
      home: 'Главная',
      about_us: 'О нас',
      privacy_policy: 'Политики',
      contact_number: '',
      follow_us_by: 'СоцСети',

      //header
      profile: 'Профиль',
      page: 'Страница',

      // User data fields
      user_name: 'НАИМЕНОВАНИЕ',
      user_country: 'Страна',
      user_zip: 'Индекс',
      user_state: 'Штат/Провинция',
      user_city: 'Город',
      user_street: 'Улица',
      user_building: 'Дом',
      user_flat: 'Квартира/Офис',
      user_phone: 'Телефон',
      user_email: 'ЭЛЕКТРОННАЯ ПОЧТА',
      user_tax: 'Налоговый номер',
      user_currency: 'ВАЛЮТА',
      user_contact_person: 'Контактное лицо',
      save: 'Сохранить',

      //Home page
      hero_text_heading: 'B2B маркетплейс автомобильных запчастей',
      hero_text_subheading:
        'Ищите товары, покупайте и продавайте по всему миру',
      hero_register: 'Зарегистрироваться',
      how_it_works: 'Как это работает?',
      how_to_start: 'Как начать?',
      card_search_parts: 'Поиск автозапчастей',
      card_search_parts_text:
        'Вы можете найти товары по номеру или через проценку файлом, сравнить позиции и выбрать самый подходящий товар.',
      place_order: 'Оформить заказ',
      place_order_text:
        'Выбрав подходящие позиции, вы можете указать тип и направление доставки и заказать товар в пару кликов.',
      track_order: 'Трекинг заказов',
      track_order_text:
        'Вы можете отслеживать процесс исполнения ваших заказов.',
      what_can_it_do: 'Что умеет All Car Parts?',
      each_option_better: 'Каждая функция помогает работать эффективнее',
      main_advantage: 'Главное преимущество',
      data_in_the_palm: 'Все данные как на ладони',
      brief_about_us: 'Коротко о нас',
      about_us_text1:
        'AllCarParts DWC-LLC специализируется на оптовых поставках OEM и Aftermarket автомобильных запчастей, а также запчастей для грузовиков и строительной техники.',
      about_us_text2:
        'Мы предлагаем широкий ассортимент автозапчастей всех производителей, включая Toyota, Mitsubishi, Honda, Nissan, Mazda, Suzuki, Ford, Kia, Hyundai, Subaru, Mercedes, Lexus, Rover, Chevrolet, BMW, Isuzu, Renault, Daewoo, Land Rover, Daihatsu и многих других.',
      watch_our_promo: 'Посмотрите наш промо-ролик',

      //Home page cards:
      card1Text: 'Гибкий поиск автозапчастей',
      card1Action: 'Перейти к поиску',
      card2Text: 'Готовые прайс-листы автозапчастей',
      card2Action: 'Посмотреть прайс-листы',
      card3Text: 'Контроль качества каждого товара',
      card4Text:
        'На нашем вебсайте клиенты из 47 стран мира уже совершают покупки',
      card4Action: 'Стать партнером',
      card5Text: 'Оперативная и эффективная доставка в любую точку мира',
      card5Action: 'Попробовать',
      card6Text:
        'Большой выбор высококачественных товаров от наших поставщиков.',
      hugeCard1Title: 'Наша команда',
      hugeCard1Text: `Каждый наш сотрудник является специалистом своего дела и имеет обширный опыт в автомобильной индустрии. Мы работаем для вас в режиме 24/7.
                      Связаться с нами можно по электронной почте или по телефону. Мы стремимся предоставить нашим клиентам наилучший опыт.`,
      hugeCard2Title: 'Расположение',
      hugeCard2Text: `Наша компания стратегически расположена в одной из свободных экономических зон Дубая.
                      Такое расположение позволяет нам выполнять поставки быстро и без налогов.`,
      hugeCard3Title: 'Купить или продать',
      hugeCard3Text: `Мы предлагаем лучшие цены и приглашаем к сотрудничеству как покупателей, так и поставщиков. Если вы заинтересованы в покупке или продаже автозапчастей, пожалуйста, напишите нам.
                      Наш представитель свяжется с вами в течение 24 часов с момента получения вашего письма.`,
      form_header: 'Форма заявки',
      form_section_header:
        'У Вас остались вопросы или хотите стать нашим партнером?',
      form_section_subheader:
        'Оставьте заявку, наши менеджера свяжутся с Вами в рабочее время',
      form_field_name: 'Введите Ваше имя',
      form_field_email: 'Введите почту',
      formTab_contact_manager: 'Связаться с менеджером',
      formTab_become_partner: 'Хочу стать партнером',
      form_button: 'Отправить',
      form_data_not_processed:
        'Ваши данные не обрабатываются и используются только для связи с вами!',
      office_address: 'Адрес офиса',

      //Timeline Home page
      item1Heaging: 'Регистрация на сайте',
      item2Heaging: 'Активация аккаунта',
      item3Heaging: 'Поиск автозапчастей',
      item4Heaging: 'Добавление в корзину и оплата',
      item5Heaging: 'Вот и всё!',
      itemManualHeading: 'Ещё остались вопросы?',
      item1Content: '',
      item2Content: `
                    Для использования всех функций нашей платформы и оформления заказов, необходимо пополнить баланс вашего аккаунта на сумму <b>500 долларов</b>.
                    <br> <br> Вы можете пополнить баланс в конце регистрации или позднее через личный кабинет.`,

      item3Content: `У нас есть два удобных способа поиска: по номеру запчасти и с помощью загрузки файла для групповой проценки.
                      <br> <br> Вы сможете быстро найти конкретные позиции, а также указать срок, тип и направление доставки.
                      `,
      item4Content: `
                      После того как вы нашли подходящие запчасти, указали сроки и тип доставки, добавьте эти товары в корзину.
                      <br> <br>Для завершения заказа пополните баланс на 15% от общей стоимости заказа. У вас откроется возможность заказать товар.`,
      item5Content: `Теперь вы можете отслеживать статус своего заказа, а вся история заказов и транзакций сохраняется у вас.`,

      itemManualContent: 'Ознакомьтесь с нашими <1>руководствами</1>',
      itemManualContent2: `Вы найдете пошаговые инструкции по работе с каждой частью нашей платформы: Регистрация и вход в аккаунт,
                            поиск, фильтрация товаров и предложений, оформление заказа, отслеживание уже существующих заказов, добавление направлений.`,

      //news
      news: 'Новости',
      news_section: 'Новостной портал',
      news_title: 'Следите за новостями мира и обновлениями платформы',
      jump_to_news: 'Перейти',
      check_all_news: 'Посмотреть все новости',
      other_news: 'Другие новости',
      author: 'Автор',
      share: 'Поделиться',
      new_news: 'Здравствуйте! У нас для Вас срочная новость.',

      // Search page
      by_part_number: 'Поиск по номеру',
      by_excel_file: 'Проценка файлом',
      to_cart: 'В корзину',
      export: 'Экспорт',
      upload: 'Загрузить',
      upload_csv_file: 'Загрузите Excel файл',
      max_days: 'Выберите срок готовности для доставки',
      template: 'Пример шаблона для загрузки',
      demo_prices_log_in:
        'Вы видите ДЕМО цены и ограниченное предложение. Авторизуйтесь',
      demo_prices_complete_registration:
        'Вы видите ДЕМО цены и ограниченное предложение. Внесите депозит USD500, чтобы завершить регистрацию',
      for_order: 'ЗАКАЗ',
      error_quotation: 'Ошибка выполнения котировки',
      quotation_differs:
        'По части позиций отличается цена, наличие или срок поставки',
      where_to_dispatch: 'Доставка',
      choose_search: 'Выберите тип поиска',
      provide_part_number: 'Введите номер запчасти',
      choose_delivery_method: 'Укажите тип доставки',
      choose_currency: 'Выберите валюту',
      nothing_found: 'По запросу “{{item}}” ничего не найдено',
      no_results_found:
        'По Вашему запросу ничего не найдено, попробуйте загрузить другой файл и убедитесь,что Ваш файл заполнен по шаблону. Как это сделать, нажмите',
      here: 'здесь',
      add_delivery_method: 'Добавить своё направление',
      load_all_items: 'Загрузить все предложения',
      collapse: 'Свернуть',

      //searh help dialog
      percentageByFilePart1: 'Проценка файлом',
      percentageByFilePart2: ' - поиск по Вашему списку номеров автозапчастей.',
      downloadExampleTemplate: 'Скачайте пример шаблона для заполнения.',
      fillAndSaveFile:
        'Заполните и сохраните Excel-файл в предопределенном формате номерами, которые вы хотите проценить.',
      matchSheetColumnNames:
        'Обязательно соблюдать соответствие шаблону наименований листа и колонок.',
      mandatoryColumnPartNumber:
        'Единственная обязательная для заполнения колонка - это <1>partnumber</1>, остальные колонки опциональны (заполнение опциональных колонок лишь уточняют ваши требования для поиска).',
      specifyDeliveryTime:
        'Укажите ваше ограничение по сроку поставки (до нашего склада) в поле <1>Выберите срок готовности для доставки</1>',
      uploadFile: 'Загрузите файл.',
      seeListQuote: 'Вы увидите котировку вашего списка на экране.',
      enterQuantitiesAddToCart:
        'Вы можете проставить <1>количество</1> для заинтересовавших вас позиций и нажать кнопку <1>Добавить в корзину</1>, чтобы переместить выбранные позиции в корзину для заказа, или нажать кнопку <1>Экспорт</1> чтобы скачать результаты проценки в виде Excel-файла.',

      //delivery dialog
      edit_delivery_heading: 'Редактирование способа отгрузки',
      select_destination: 'Выберите точку назначения',
      delivery_days:
        'Укажите дни отгрузки или "по запросу" для отправки по запросу',
      choose_carrier: 'Выберите перевозчика',
      min_weight: 'Введите минимальный вес в кг',
      destination_active: 'Направление активно',
      no_carrier: ' Нет перевозчика в списке? <1>Напишите нам</1>',

      // Balance page
      cannot_export_invoice: 'Ошибка выгрузки документа',
      start_date: 'Дата начала',
      end_date: 'Дата конца',
      show: 'Показать',
      topup_online: 'Пополнить картой',
      transaction_history: 'История транзакции',
      specify_period: 'Укажите период',
      bank_transfer: 'Банковский перевод',
      document: 'Документ',
      debit: 'Дебет',
      credit: 'Кредит',

      // Basket page
      cannot_select_item: 'Ошибка при выборе позиций в заказ',
      cannot_change_quantity: 'Ошибка изменения количества',
      cannot_apply_changes: 'Ошибка подтверждения изменений',
      cannot_order_selected: 'Ошибка размещения заказа',
      cannot_delete_selected: 'Ошибка при удалении',
      order: 'Заказ',
      remove: 'Убрать',
      order_accepted:
        'Ваш заказ № {{orderId}} в сумме {{currency}}{{amount}} принят.',
      order_not_accepted:
        'Ваш заказ не принят, код ошибки (code = {{orderId}})',
      activate_account: 'Свяжитесь с нами для активации аккаунта',
      insufficient_funds: 'Недостаточно средств на балансе',
      no_items: 'Не выбраны позиции в заказ',
      duplication: 'Дублирование',
      basket_log_in: 'Для размещения заказов авторизуйтесь',
      basket_complete_registration:
        'Для размещения заказов внесите депозит USD500, чтобы завершить регистрацию',
      proceed: 'Перейти',

      //Select topUp dialog
      choose_payment_method: 'Выберите способ пополнения',
      online_payment_method: 'Оплата онлайн',
      bank_transfer_payment_method: 'Банковский перевод',

      // Orders page
      part_number: 'Номер детали',
      order_number: 'Номер заказа',
      reference: 'Референс',
      change_delivery: 'Сменить метод доставки',
      change_delivery_text: 'Смена метода доставки для {{number}} товара(ов)',
      send_refusal: 'Отказаться',
      refusal_indicator: 'Отказ принят',
      refuseDialog: {
        title: 'Вы уверены, что хотите отказаться от выбранных заказов?',
        cancel: 'Отмена',
        confirm: 'Подтвердить',
      },
      all: 'Все',
      provide_order_number: 'Введите номер заказа',
      deliveryChangeErrors:
        'При изменении доставки некоторых товаров произошли ошибки.',
      provider_reference: 'Введите референс',

      // add delivery dialog
      addingShippingMethod: 'Добавление способа отгрузки',
      selectDestination: 'Выберите точку назначения',
      specifyShippingDays:
        "Укажите дни отгрузки или 'по запросу' для отправки по запросу",
      selectCarrier: 'Выберите перевозчика',
      carrierNotInList: 'Нет перевозчика в списке?',
      contactUs: 'Напишите нам',
      enterMinimumWeight: 'Введите минимальный вес в кг',
      destinationActive: 'Направление активно',

      // Password reset page
      submit: 'Сменить',
      new_password: 'Новый пароль',

      //password errors

      // Register page
      provide_registration_data_text:
        'Введите регистрационные данные для дальнейшего входа в систему',
      register_step1: 'Данные для входа',
      register_step2: 'Персональные данные',
      register_step3: 'Активация аккаунта',
      register_step4: 'Регистрация завершена',
      header_have_account: 'Уже есть аккаунт?',
      next_step: 'След. Шаг',
      prev_step: 'Назад',
      skip: 'Пропустить',
      not_selected: 'Не выбрано',
      san_francisco: 'Сан-Франциско',
      california: 'Калифорния',
      contact_person: 'Андрей',
      fill_later: 'Вы можете пропустить этот шаг и заполнить эту форму позже.',
      top_up_later: 'Вы можете пропустить этот шаг и пополнить кошелёк позже.',
      activation_sub_title1:
        'Активировав свой аккаунт, Вы сможете создавать заказы и скачивать <1>прайс-листы</1>',
      activation_sub_title2:
        'Для активации аккаунта необходимо пополнить кошелёк на сумму <1>$500</1>',
      activation_sub_title4:
        'Если у Вас есть вопросы, Вы можете связаться с нами по почте <1>sales@allcarparts.ae</1>',
      password_requiremests: 'Пароль не соответствует требованиям',
      password_requiremests_head: 'Требования к паролю:',
      password_at_least_chars: '- не менее 8 символов',
      password_at_least_special: '- минимум 1 спец. знак',
      password_at_least_digit: '- минимум 1 цифра',
      header_step: 'Шаг',

      //Profile page
      old_password: 'Старый пароль',
      acc_data: 'Данные аккаунта',
      personal_data: 'Персональные данные',

      //Log in page
      log_in_head: 'Вход',
      reset_password: 'Восстановление пароля',
      reset: 'Восстановить',
      register_button: 'Создать аккаунт',
      header_log_in: 'Авторизация',
      header_first_time_here: 'Впервые здесь?',

      // Shipments page
      cannot_update_shipment_date: 'Ошибка изменения даты отгрузки',
      edit_delivery: 'Редактировать',

      // Balance topup dialog
      amount_with_currency: 'Сумма ({{currency}})',
      charge: 'КОМИССИЯ: {{percent}}% + {{fixed}} {{currency}}',
      topup: 'Пополнить',

      // Balance total table
      balance_with_currency: 'Баланс ({{currency}})',
      in_orders: 'В работе',
      in_cart: 'В корзине',
      pay_for_cart: 'Оплатить до корзины',
      ready_to_ship: 'К отгрузке',
      pay_to_ship: 'Оплатить к отгрузке',

      // Basket table
      brand: 'Марка',
      price_with_currency: 'Цена ({{currency}})',
      quantity: 'Количество',
      total_with_currency: 'Сумма ({{currency}})',
      weight_kg: 'Вес (кг)',
      booking: 'Букинг',
      delivery: 'Доставка',
      description: 'Описание',

      // Basket total table
      to_order: 'К заказу',
      available_for_order: 'Доступно к заказу',
      pay_for_order: 'Оплатить к заказу',
      items: 'Позиций',

      // Captcha
      captcha_field_placeholder: 'ВВЕДИТЕ СИМВОЛЫ НА КАРТИНКЕ',

      //Catalog page
      offers_log_in: 'Для скачивания предложений авторизуйтесь',
      offers_complete_registration:
        'Для скачивания предложений внесите депозит USD500, чтобы завершить регистрацию',
      delivery_ready_term: 'Выберите срок готовности для доставки',
      enter_parts_brand: 'Введите марки запчасти',

      // Catalog table
      offer: 'Предложение',
      download: 'Скачать',
      lines: 'Строк',
      updated: 'Обновлено',

      //Email verified page
      email_verified: 'Почтовый адрес подтвержден',
      cancel: 'Отменить',

      // Log In dialog
      email: 'email',
      password: 'Пароль',
      forgot_password: 'Забыли пароль?',
      reset_link_sent: 'Ссылка для сброса пароля отправлена вам на почту',
      log_in: 'Войти',
      error_login: 'Логин и/или пароль некорректны',

      // Orders table
      date: 'Дата',
      order_price: 'Цена заказа',
      sale_price: 'Цена продажи',
      ordered: 'Заказано',
      purchased: 'Закуплено',
      shipped: 'Отгружено',
      refused: 'Отказано',
      state_date: 'Дата состояния',
      invoice: 'Отправление',

      // Search input
      search_input_placeholder: 'Поиск...',

      // Search table
      substituted: 'Замена',
      days: 'Дней',
      available: 'Наличие',
      volume_kg: 'Объем (кг)',
      comment: 'Комментарий',
      n_a: 'НЕТ',

      // Shipment date dialog
      enabled: 'Активно',

      // Shipment box table
      row_id: 'Row ID',

      // Shipment table
      place: 'Грузоместо',
      length_m: 'Длина (м)',
      height_m: 'Высота (м)',
      width_m: 'Ширина (м)',
      value_with_currency: 'Сумма ({{currency}})',

      // Shipment total table
      places: 'Грузомест',
      volume_m3: 'Объем (㎥)',
      schedule: 'Дата отгрузки',
      choose_date: 'Выберите дату',

      // Sign up dialog
      company_name: 'Наименование компании',
      contact_phone: 'Номер телефона',
      confirm_password: 'Подтвердите пароль',
      sign_up: 'Регистрация',
      error_password_not_match: 'Пароли не совпадают',

      //contact page

      warehouse_location: 'Местоположение склада',

      //Manuals page
      tab_register: 'Зарегистрироваться',
      tab_quotations: 'Проценить',
      tab_offers: 'Скачать прайсы',
      tab_api: 'Подключиться к API',

      //TopUp Dialog
      error_amount: 'Неверная сумма',
      error_something: 'Что-то пошло не так, свяжитесь с нами',
    },
  },
  ar: {
    translation: {
      //Page Tabs
      search: 'بحث',
      cart: 'سلة',
      orders: 'الطلبات',
      dispatch: 'إرسال',
      balance: 'الرصيد',
      login: 'الدخول الى النظام',
      register: 'التسجيل',
      logout: 'الخروج من النظام',
      manuals: 'تعليمات',
      offers: 'عروض',
      api: 'التكامل',
      quotation: 'عرض السعر',
      fill_required: 'املأ الحقل المطلوب',
      fill_latin: 'استخدم الحروف والأرقام اللاتينية فقط',
      man_orders: ' تتبع الطلبات',

      //footer
      terms_and_conditions: 'الشروط والأحكام',
      contacts: 'جهات الاتصال',
      contact_us: 'اتصل بنا',
      home: 'الصفحة الرئيسية',
      about_us: 'معلومات عنا',
      privacy_policy: 'من نحن',
      follow_us_by: 'تابعونا',

      //header
      Profile: 'الملف الشخصي',
      page: 'صفحة',

      // User data fields
      user_name: 'الاسم',
      user_country: 'البلد',
      user_zip: 'الرمز البريدي',
      user_state: 'الدولة / المقاطعة',
      user_city: 'المدينة',
      user_street: 'الشارع',
      user_building: 'البيت',
      user_flat: 'الشقة / المكتب',
      user_phone: 'الهاتف',
      user_email: 'عنوان البريد الإلكتروني',
      user_tax: 'الرقم الضريبي',
      user_currency: 'العملة',
      user_contact_person: 'جهة الاتصال',
      save: 'حفظ',

      //Home page
      hero_text_heading: 'B2B سوق قطع غيار السيارات',
      hero_text_subheading:
        'ابحث عن المنتجات، وقم بالشراء والبيع في جميع أنحاء العالم',
      hero_register: 'تسجيل',
      how_it_works: 'كيف يعمل؟',
      how_to_start: 'كيف تبدأ؟',
      card_search_parts: 'البحث عن قطع غيار السيارات',
      card_search_parts_text:
        'يمكنك البحث عن قطع الغيار حسب الرقم أو الأجزاء ومقارنتها واختيار الأنسب.',
      place_order: 'وضع الطلب',
      place_order_text:
        'بمجرد تحديد قطع الغيار المناسبة ، يمكنك تحديد نوع ومكان التسليم وطلب البضائع في بضع نقرات.',
      track_order: 'تتبع الطلبات',
      track_order_text: '.يمكنك تتبع تنفيذ أوامرك',
      what_can_it_do: '؟All Car Parts ما الذي تقوم به شركة ',
      each_option_better: '',
      main_advantage: 'الميزة الرئيسية',
      data_in_the_palm: 'جميع البيانات بين يديك',
      brief_about_us: 'باختصار من نحن',
      about_us_text1:
        'شركة AllCarParts DWC-LLC متخصصة في توريد الجملة لقطع الغيار الأصلية وقطع غيار السيارات السوق الثانوي، فضلا عن قطع الغيار للشاحنات ومعدات البناء.',
      about_us_text2:
        'نحن نقدم مجموعة واسعة من قطع الغيار من جميع الشركات المصنعة، بما في ذلك شركات Toyota، Mitsubishi، Honda، Nissan، Mazda، Suzuki، Ford، Kia، Hyundai، Subaru، Mercedes، Lexus، Rover، Chevrolet، BMW، Isuzu، Renault، Daewoo، Land Rover، Daihatsu وغيرها الكثير.',
      watch_our_promo: 'شاهدوا اعلاناتنا التجارية',

      //Home page cards:
      card1Text: 'البحث عن قطع غيار للسيارات مع خيارات مرنة',
      card1Action: 'الانتقال إلى البحث',
      card2Text: 'قوائم اسعار لقطع غيار السيارات',
      card2Action: 'عرض قوائم الأسعار',
      card3Text: 'مراقبة الجودة لكل منتج',

      card4Text: 'لدينا عملاء من 47 دولة يشترون بالفعل المنتجات على موقعنا',
      card4Action: 'اصبح شريكنا',
      card5Text: 'تسليم سريع وفوري في أي مكان في العالم',
      card5Action: 'اختبر خدماتنا',
      card6Text: ' .مجموعة كبيرة من المنتجات عالية الجودة من موردينا',
      hugeCard1Title: 'فريقنا',
      hugeCard1Text:
        'كل عضو في فريقنا هو خبير في مجاله ولديه خبرة واسعة في صناعة السيارات نحن نقدم لك الخدمات على مدار الساعة. يمكنك الاتصال بنا عن طريق البريد الإلكتروني أو عن طريق الهاتف. ونحن نسعى جاهدين لضمان أن عملائنا لديهم فقط أفضل الانطباعات من العمل معنا.',
      hugeCard2Title: 'موقع الشركة',
      hugeCard2Text:
        '.تقع شركتنا في موقع استراتيجي في إحدى المناطق الاقتصادية الحرة في دبي. يتيح لنا هذا الموقع التسليم بسرعة وبدون ضرائب',
      hugeCard3Title: 'قم بالشراء أو البيع',
      hugeCard3Text:
        '.نحن نقدم أفضل الأسعار وندعو التعاون من كل من المشترين والموردين. إذا كنت مهتمًا بشراء أو بيع قطع غيار السيارات، يرجى الكتابة إلينا. سيتصل بك مندوبنا خلال 24 ساعة من استلام بريدك الإلكتروني',
      form_header: 'نموذج الطلب',
      form_section_header: 'هل لديك أي أسئلة أو تريد أن تصبح شريكنا؟',
      form_section_subheader: 'اترك طلباً، سيتصل بك مديرونا خلال ساعات العمل',
      form_field_name: 'أدخل اسمك',
      form_field_email: 'أدخل عنوان بريدك الإلكتروني',
      formTab_contact_manager: 'اتصل بالمدير',
      formTab_become_partner: 'اصبح شريكنا',
      form_button: 'إرسال',
      form_data_not_processed:
        'لا تتم معالجة البيانات الخاصة بك وتستخدم للاتصال بك فقط!',
      office_address: 'عنوان المكتب',

      //Timeline Home page
      item1Heaging: 'التسجيل في الموقع',
      item2Heaging: 'تفعيل حسابك',
      item3Heaging: 'البحث عن قطع غيار للسيارات',
      item4Heaging: 'وضع الطلبات ودفع قيمة الطلبات',
      item5Heaging: 'هذا كل شيء!',
      itemManualHeading: 'Still have questions?',
      item2Content:
        '.من أجل استخدام جميع ميزات منصتنا وتقديم الطلبات، تحتاج إلى تعبئة حسابك بمقدار 500 دولار. يمكنك عمل إيداع في نهاية التسجيل أو لاحقًا عبر حسابك الشخصي',
      item3Content:
        '.لدينا طريقتان مناسبتان للبحث: عن طريق رقم الجزء وعن طريق تحميل ملف للبحث الجماعي. يمكنك البحث بسرعة عن عناصر محددة وتحديد تاريخ التسليم والنوع والوجهة',
      item4Content:
        '.بمجرد العثور على الأجزاء الصحيحة، وتحديد وقت التسليم ونوعه، قم بإضافة هذه العناصر إلى سلة التسوق الخاصة بك. قم بتعبئة 15% من إجمالي قيمة الطلب لإكمال الطلب. سيكون لديك بعد ذلك الفرصة لطلب البضائع',
      item5Content:
        '.يمكنك الآن تتبع حالة طلبك؛ يتم تخزين تاريخ طلبك بالكامل وسجل المعاملات في حسابك',
      itemManualContent: 'Check out our <1>manuals</1>',
      itemManualContent2: `You will find step-by-step instructions on how to work with each part of our platform: Registering and logging into your account,
                             searching, filtering products and offers, placing an order, tracking existing orders, and adding destinations.`,
      //news
      news: 'الاخبار',
      news_section: 'قسم الأخبار',
      news_title: 'Stay tuned for world news and platform updates',
      jump_to_news: 'القراءة',
      check_all_news: 'تحقق من كل الأخبار',
      other_news: 'أخبار أخرى',
      author: 'المؤلف',
      share: 'المشاركة',
      new_news: 'مبروك! لدينا أخبار عاجلة لك..',

      // Search page
      by_part_number: 'بواسطة رقم قطعة الغيار',
      by_excel_file: 'بواسطة ملف اكسل',
      to_cart: 'إلى السلة',
      export: 'تصدير',
      upload: 'رفع',
      upload_csv_file: 'бرفع ملف اكسل',
      max_days: 'حدد الموعد النهائي للتسليم',
      template: 'مثال للنموذج',
      demo_prices_log_in:
        'ترون الأسعار في وضع العرض. للحصول على معلومات حول الأسعار الحقيقية، يرجى تسجيل الدخول',
      demo_prices_complete_registration:
        ' ترون الأسعار في وضع العرض. للحصول على معلومات حول الأسعار الحقيقية، يرجى تعبئة حسابك بمبلغ 500 دولار أمريكي لإكمال عملية التسجيل',
      for_order: 'الذهاب إلى الطلبات',
      error_quotation: 'لا يمكن معالجة عرض السعر بسبب خطأ',
      quotation_differs:
        'بعض قطع الغيار التي يمكنك شراؤها بمواعيد تسليم أو أسعار أو كميات جديدة',
      where_to_dispatch: 'التسليم',
      choose_search: 'حدد طريقة البحث',
      provide_part_number: 'حدد رقم قطع الغيار في الكتالوج',
      choose_delivery_method: 'حدد طريقة التسليم',
      choose_currency: 'اختر العملة',
      nothing_found: '"{{item}}" لم يتم العثور على شيء حسب الطلب',
      add_delivery_method: 'إضافة معلومات خاصة بالوجهة',
      load_all_items: 'تحميل جميع الطلبات',
      collapse: 'تقليص',

      //searh help dialog
      percentageByFilePart1: 'Quotation by file',
      percentageByFilePart2: ' - search by your list of auto parts numbers.',
      downloadExampleTemplate: 'Download the example template for filling out.',
      fillAndSaveFile:
        'Fill out and save the Excel file in the predetermined format with the numbers you want to evaluate.',
      matchSheetColumnNames:
        'Be sure to match the template sheet and column names.',
      mandatoryColumnPartNumber:
        'The only mandatory column to fill out is partnumber, all other columns are optional (filling out optional columns will only clarify your requirements for the search).',
      specifyDeliveryTime:
        'Specify your delivery time constraint (to our warehouse) in the field Select the readiness term for delivery',
      uploadFile: 'Upload the file.',
      seeListQuote: 'You will see the quote for your list on the screen.',
      enterQuantitiesAddToCart:
        'You can enter quantities for the positions that interest you and click the Add to Cart button to move the selected positions to the cart for ordering, or click the Export button to download the pricing results as an Excel file.',

      //delivery dialog
      edit_delivery_heading: 'Edit delivery',
      select_destination: 'Select destination',
      delivery_days:
        'Specify shipping days or "On request" for on-demand shipments',
      choose_carrier: 'Choose carrier',
      min_weight: 'Enter minimum weight in kg',
      destination_active: 'Destination is active',
      no_carrier: 'No carrier in the list? <1>Write to us</1',

      // Balance page
      cannot_export_invoice: 'تعذر تصدير الفاتورة بسبب خطأ',
      start_date: 'تاريخ البدء',
      end_date: 'تاريخ الانتهاء',
      show: 'عرض',
      transaction_history: 'سجل المعاملات',
      specify_period: 'تحديد الفترة',
      topup_online: 'التعبئة بواسطة الانترنت',
      bank_transfer: 'حوالة مصرفية',
      document: 'وثيقة',
      debit: 'النفقات',
      credit: 'الأرباح',

      // Basket page
      cannot_select_item: 'لا يمكن تحديد قطع الغيار بسبب الخطأ',
      cannot_change_quantity: 'لا يمكن تغيير الكمية بسبب الخطأ',
      cannot_apply_changes: 'لا يمكن تطبيق التغييرات بسبب الخطأ',
      cannot_order_selected: 'لا يمكن حجز المنتج المحدد بسبب الخطأ',
      cannot_delete_selected: 'لا يمكن حذف المنتج المحدد بسبب الخطأ',
      order: 'طلب',
      remove: 'حذف',
      order_accepted:
        'طلبك رقم {{currency}}{{amount}} بمبلغ {{orderId}} قد تم قبوله',
      order_not_accepted: '({{orderId}} = الرمز) لم يتم قبول طلبك بسبب خطأ',
      activate_account: 'يرجى الاتصال بنا لتفعيل حسابك',
      insufficient_funds: 'الأموال غير كافية ، يرجى التحقق من رصيدك',
      no_items: 'لا توجد قطع غيار للطلب',
      basket_log_in: 'لوضع الطلبات، يرجى تسجيل الدخول',
      basket_complete_registration:
        'لوضع الطلبات ، يرجى إيداع 500 دولار أمريكي لإكمال التسجيل',
      proceed: 'متابعة',

      // Orders page
      part_number: 'رقم قطعة الغيار وفق الكاتالوج',
      order_number: 'رقم الطلب',
      reference: 'الرابط',
      change_delivery: 'تغيير التوصيل',
      change_delivery_text: 'تغيير التوصيل لعدد {{number}} من العناصر',
      send_refusal: 'رفض',
      refusal_indicator: 'تم قبول الرفض',
      refuseDialog: {
        title: 'هل أنت متأكد أنك تريد رفض الطلبات المحددة؟',
        cancel: 'إلغاء',
        confirm: 'تأكيد',
      },
      all: 'الجميع',
      provide_order_number: 'أدخل رقم الطلب',
      deliveryChangeErrors: 'حدثت أخطاء أثناء تغيير تسليم بعض العناصر.',
      provider_reference: 'أدخل الرابط',

      // Password reset page
      submit: 'تأكيد',
      new_password: 'كلمة مرور جديدة',

      // Register page
      provide_registration_data_text:
        'أدخل بيانات التسجيل لمواصلة تسجيل الدخول',
      register_step1: 'بيانات تسجيل الدخول',
      register_step2: 'البيانات الشخصية',
      register_step3: 'تفعيل الحساب',
      register_step4: 'اكتمل التسجيل',
      header_have_account: 'هذا الحساب موجود بالفعل',
      next_step: 'الخطوة التالية',
      prev_step: 'العودة',
      skip: 'تخطي',
      not_selected: 'غير محدد',
      san_francisco: 'سان فرانسيسكو',
      california: 'كاليفورنيا',
      contact_person: 'أندري',
      fill_later: 'يمكنك تخطي هذه الخطوة وملء النموذج لاحقا',
      top_up_later: 'يمكنك تخطي هذه الخطوة وتعبئة محفظتك لاحقا.',
      activation_sub_title1:
        '</1>بعد تفعيل حسابك، يمكنك إنشاء الطلبات وتحميل < 1 > الفواتير.',
      activation_sub_title2:
        '</1>لتفعيل حسابك ، تحتاج إلى تعبئة حسابك بمبلغ <1> 500 دولار أمريكي',
      activation_sub_title4:
        'إذا كانت لديك أي أسئلة ، يمكنك التواصل معنا على العنوان التالي <1>sales@allcarparts.ae</1>',
      password_requiremests: 'كلمة المرور لا تتطابق مع المتطلبات اللازمة',
      password_requiremests_head: 'متطلبات كلمة المرور',
      password_at_least_chars: ' – 8 أحرف على الأقل',
      password_at_least_special: 'على الأقل رمز خاص واحد ',
      password_at_least_digit: 'على الأقل رقم واحد',
      //Profile page
      old_password: 'كلمة المرور القديمة',
      acc_data: 'بيانات الحساب',
      personal_data: 'البيانات الشخصية',
      header_step: 'خطوة',

      //Log in page
      log_in_head: 'تسجيل الدخول',
      reset_password: 'إعادة تعيين كلمة المرور',
      reset: 'إعادة تعيين',
      register_button: 'إنشاء حساب',
      header_log_in: 'تسجيل الدخول',
      header_first_time_here: 'هل انت هنا في اول مرة؟',

      // Shipments page
      cannot_update_shipment_date:
        'لا يمكن تحديث بيانات تاريخ التسليم بسبب خطأ',
      edit_delivery: 'تحرير',

      // Balance topup dialog
      amount_with_currency: '({{currency}}) الكمية',
      charge: '{{currency}} {{fixed}} + % {{percent}} :المبلغ المطلوب خصمه',
      topup: 'تعبئة',

      // Balance total table
      balance_with_currency: '({{currency}}) الرصيد',
      in_orders: 'في الطلبات',
      in_cart: 'في السلة',
      pay_for_cart: 'دفع ثمن المتواجد في السلة',
      ready_to_ship: 'جاهز للشحن',
      pay_to_ship: 'دفع ثمن الشحن',

      // Basket table
      brand: 'العلامة التجارية',
      price_with_currency: '({{currency}}) السعر',
      quantity: 'الكمية',
      total_with_currency: '({{currency}}) المجموع',
      weight_kg: 'الوزن (كلغ)',
      booking: 'تسجيل النظام',
      delivery: 'التسليم',
      description: 'الوصف',
      duplication: 'الازدواجية',

      // Basket total table
      to_order: 'للطلب',
      available_for_order: 'متاح للطلب',
      pay_for_order: 'دفع قيمة الطلب',
      items: 'قطع الغيار',

      // Captcha
      captcha_field_placeholder: 'أدخل النص من الصورة المتواجدة أعلاه',

      //Catalog page
      offers_log_in: 'لتحميل العروض ، يرجى تسجيل الدخول',
      offers_complete_registration:
        'لتنزيل العروض، يرجى إيداع 500 دولار امريكي الى الحساب لإكمال التسجيل',
      delivery_ready_term: 'حدد وقت الاستعداد للتسليم',
      enter_parts_brand: 'أدخل العلامات التجارية لمصنعي قطع الغيار',

      // Catalog table
      offer: 'عرض',
      download: 'تحميل',
      lines: 'سطور',
      updated: 'تم التحديث',

      //Email verified page
      email_verified: 'تم التحقق من عنوان بريدك الإلكتروني',
      cancel: 'إلغاء',

      // Log In dialog
      email: 'عنوان البريد الإلكتروني',
      password: 'كلمة المرور',
      forgot_password: 'نسيت كلمة المرور؟',
      reset_link_sent:
        'تم إرسال رابط إعادة تعيين كلمة المرور إلى عنوان بريدك الإلكتروني',
      log_in: 'تسجيل الدخول',
      error_login: 'معلومات تسجيل الدخول غير صالحة و / أو كلمة المرور',

      // Orders table
      date: 'التاريخ',
      order_price: 'سعر الطلب',
      sale_price: 'سعر البيع',
      ordered: 'الطلب',
      purchased: 'تم شراؤه',
      shipped: 'تم الارسال',
      refused: 'مرفوض',
      state_date: 'حد التاريخ',
      invoice: 'شحنة',

      // Search input
      search_input_placeholder: 'بحث...',

      // Search table
      substituted: 'استبدال',
      days: 'عدد الأيام',
      available: 'متاح',
      volume_kg: 'الحجم (كلغ)',
      comment: 'تعليق',
      n_a: 'لا ينطبق',

      // Shipment date dialog
      enabled: 'تم تفعيله',

      // Shipment box table
      row_id: 'معرف السطر',

      // Shipment table
      place: 'مقعد',
      length_m: 'الطول (م)',
      height_m: 'الارتفاع (م)',
      width_m: 'العرض (م)',
      value_with_currency: '({{currency}}) التكلفة',

      // Shipment total table
      places: ' عدد المقاعد',
      volume_m3: 'الحجم (م 3)',
      schedule: 'الرسم البياني',
      choose_date: 'حدد التاريخ',

      // Sign up dialog
      company_name: 'اسم الشركة',
      contact_phone: 'رقم الهاتف للاتصال',
      confirm_password: 'تأكيد كلمة المرور',
      sign_up: 'سجيل',
      error_password_not_match: 'كلمات المرور لا تتطابق',

      //contact page
      warehouse_location: 'مكان تواجد المستودع',

      //Manuals page
      tab_register: 'سجل',
      tab_quotations: 'عرض الأسعار',
      tab_offers: 'تحميل العروض',
      tab_api: 'استخدام واجهة البرمجة التطبيقية',

      //TopUp Dialog
      error_amount: 'مبلغ غير صحيح',
      error_something: 'حدث خطأ ما ، اتصل بنا للحصول على المساعدة',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('languageCode')
      ? localStorage.getItem('languageCode')
      : 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { SERVER_DATE_FORMAT } from '../utils/date';
import { isEnterKey } from '../utils/keyEvents';

import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  Popover,
  Button,
  withStyles,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { DatePicker } from './index';

const ShipmentDateDialog = ({
  classes,
  open,
  defaultDate,
  onClose,
  onSubmit,
  onOpen,
  anchor,
}) => {
  const { t } = useTranslation();

  const minDate = moment().startOf('day').add(1, 'day');
  const initDate = defaultDate ? moment(defaultDate) : minDate;
  const [enabled, setEnabled] = useState(true);
  const [date, setDate] = useState(
    initDate.isBefore(minDate) ? minDate : initDate
  );

  function handleEnterPress(event) {
    if (enabled && defaultDate && date.isSame(initDate)) return;
    if (isEnterKey(event)) {
      event.stopPropagation();
      handleSubmit();
    }
  }

  useEffect(() => {
    onOpen(open);
  }, [open]);

  function handleClose() {
    open = false;
    onClose();
  }

  function handleSubmit() {
    onSubmit(date.format(SERVER_DATE_FORMAT), enabled);
  }

  function handleDateChange(date) {
    setDate(date);
  }

  function handleEnabledChange(event) {
    setEnabled(event.target.checked);
  }

  const disabled = enabled && defaultDate && date.isSame(initDate);
  return (
    // <Dialog
    //   open={open}
    //   maxWidth="xs"
    //   fullWidth
    //   onClose={handleClose}
    //   onKeyPress={handleEnterPress}
    // >
    //   <DialogContent className={classes.dialog}>
    //     <form>
    //       <FormControlLabel
    //         control={
    //           <Checkbox checked={enabled} onChange={handleEnabledChange} />
    //         }
    //         label={t('enabled')}
    //       />
    //       <DatePicker
    //         className={classes.datePicker}
    //         minDate={minDate}
    //         value={date}
    //         disabled={!enabled}
    //         onChange={handleDateChange}
    //       />
    //     </form>

    //     <Button
    //       className={classes.button}
    //       color="primary"
    //       variant="contained"
    //       disabled={disabled}
    //       onClick={handleSubmit}
    //     >
    //       {t('submit')}
    //     </Button>
    //   </DialogContent>
    // </Dialog>
    <Popover
      open={open}
      onClose={handleClose}
      onKeyPress={handleEnterPress}
      anchorEl={anchor}
    >
      <div className={classes.dialog}>
        <form>
          <FormControlLabel
            control={
              <Checkbox checked={enabled} onChange={handleEnabledChange} />
            }
            label={t('enabled')}
          />
          <DatePicker
            variant="static"
            className={classes.datePicker}
            minDate={minDate}
            value={date}
            disabled={!enabled}
            onChange={handleDateChange}
          />
        </form>

        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          disabled={disabled}
          onClick={handleSubmit}
        >
          {t('submit')}
        </Button>
      </div>
    </Popover>
  );
};

ShipmentDateDialog.defaultProps = {
  open: true,
};

const styles = (theme) => {
  const colors = theme.palette.custom.shipmentsPage.shipmentDateDialog;
  return {
    dialog: {
      backgroundColor: colors.dialog,
    },
    button: {
      marginTop: theme.spacing(1) * 2,
      width: '100%',
    },
    datePicker: {
      width: '100%',
      backgroundColor: colors.textField.background,
    },
  };
};

export default withStyles(styles)(ShipmentDateDialog);

import React, { useState, useEffect } from 'react';
import { Drawer, Toolbar, Button, ListItemIcon } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import NavBar from './NavBar';

import { withStyles } from '@material-ui/core/styles';
import Logo from '../assets/logo.png';
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { SelectFine } from './index';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import lang_icon from '../assets/image/language_icon.png';

const PageHeader = ({
  classes,
  companyName,
  companyAddress,
  customerCode,
  isLoggedIn,
  width,
  onLogin,
  onLogout,
  onSignUp,
  onUserData,
  languageCode,
  languageList,
  languageSelect,
}) => {
  const { t } = useTranslation();

  const isMobile = isWidthDown('sm', width);
  const listShrink = !isWidthUp('lg', width) && !isMobile;
  const buttonVariant = 'contained'; //isMobile ? 'text' : 'contained';
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    !isMobile && setOpenDrawer(false);
  }, [isMobile]);

  return (
    <div className={classes.header}>
      <a href="/">
        <div className={classes.logo}>
          <img src={Logo} />
        </div>
      </a>

      <Toolbar className={classes.toolbar}>
        {isMobile ? (
          <>
            <Drawer
              className={classes.drawer}
              style={{ minWidth: '250px' }}
              open={openDrawer}
              anchor="top"
              onClose={() => setOpenDrawer(false)}
            >
              <IconButton
                style={{ justifyContent: 'flex-end', marginRight: '30px' }}
                onClick={() => setOpenDrawer(!openDrawer)}
              >
                <CloseIcon style={{ fontSize: 25, textAlign: 'right' }} />
              </IconButton>
              <NavBar
                customerCode={customerCode}
                isLoggedIn={isLoggedIn}
                onLogin={onLogin}
                onSignUp={onSignUp}
                onLogout={onLogout}
                onUserData={onUserData}
                width={width}
              />
              <div className={classes.selectFin}>
                <SelectFine
                  items={languageList}
                  value={languageCode}
                  onChange={languageSelect}
                  icon={lang_icon}
                />
              </div>
            </Drawer>
            <IconButton
              className={classes.burger}
              onClick={() => setOpenDrawer(!openDrawer)}
            >
              <MenuIcon style={{ fontSize: 20 }} />
            </IconButton>
          </>
        ) : (
          <>
            <Toolbar className={classes.toolbar}>
              <NavBar
                customerCode={customerCode}
                isLoggedIn={isLoggedIn}
                onLogin={onLogin}
                onSignUp={onSignUp}
                onLogout={onLogout}
                onUserData={onUserData}
                width={width}
              />
            </Toolbar>
            <div className={classes.selectFin}>
              <SelectFine
                items={languageList}
                value={languageCode}
                onChange={languageSelect}
                icon={lang_icon}
              />
            </div>
          </>
        )}
      </Toolbar>
    </div>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.pageHeader;
  return {
    header: {
      backgroundColor: colors.header,
      padding: '0 40px',
      maxHeight: '50px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      borderBottom: '0.5px solid rgba(0, 0, 0, 0.20)',
      [theme.breakpoints.down('sm')]: {
        minHeight: '50px',
      },
    },
    drawer: {
      '& .MuiDrawer-paperAnchorLeft': {
        minWidth: '150px',
        [theme.breakpoints.down('xs')]: {
          minWidth: '300px',
        },
      },
      '& .MuiDrawer-paperAnchorTop': {
        minHeight: '470px',
      },
      [theme.breakpoints.down('sm')]: {
        backdropFilter: 'blur(5px)',
        transition: '0.3s ease-in-out',
      },
    },
    burger: {},
    selectFin: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
      },
    },
    headerList: {
      marginTop: 0,
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        backgroundColor: 'red',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    headerButtons: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    button: {
      marginTop: 0,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    toolbar: {
      flex: '1 0 0px',
      justifyContent: 'space-between',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-end',
      },
    },
    name: {
      color: theme.palette.custom.logo,
      marginLeft: theme.spacing(1),
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      '& > img': {
        minHeight: 'auto',
        width: '142px',
      },
    },
    address: {
      color: theme.palette.custom.logo,

      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    business: {
      color: theme.palette.custom.logo,
      marginLeft: theme.spacing(1),

      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    link: {
      textDecoration: 'none',
      color: colors.navLink.fontColor,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(2),
      '&:hover': {
        color: colors.navLink.hovered,
        borderBottom: '1px solid white',
      },
    },
    linkDisabled: {
      textDecoration: 'none',
      color: colors.navLink.disabled,
      pointerEvents: 'none',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(2),
      '&:hover': {
        color: colors.navLink.disabled,
        borderBottom: '1px solid white',
      },
    },
  };
};

export default withWidth()(withStyles(styles)(PageHeader));

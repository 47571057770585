import React, { useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Page,
  CatalogTable,
  ActionsGroup,
  SelectFine,
  SelectFineTwoCol,
} from '../components';
import * as offerApi from '../api/offer-api';
import { getCookie } from '../utils/cookies';

import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

import calendar_icon from '../assets/image/calendar_icon.png';

const CatalogPage = ({ classes }) => {
  const { t } = useTranslation();
  const [isLoggedIn] = useState(!!localStorage.getItem('access_token'));
  const [isCustomer] = useState(localStorage.getItem('is_customer') === 'true');

  const [offerList, setOfferList] = useState([]);
  const [offer, setOffer] = useState([]);
  const [offerData, setOfferData] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [brand, setBrand] = useState([]);
  const initialized = useRef(false);

  async function getOfferList() {
    const result = await offerApi.offers();

    setOfferList(
      result.map((row) => ({
        title: row['text'],
        value: row['logo'],
      }))
    );

    if (result !== null && result.length !== 0) {
      setOffer(result[0].logo);
    }
  }

  async function getBrandList() {
    const result = await offerApi.brands();

    setBrandList(
      result.map((row) => ({
        title: row['text'],
        value: row['logo'],
      }))
    );

    if (result !== null && result.length !== 0) setBrand(result[0].logo);
  }

  async function fetchOfferData(offer, brand) {
    setOfferData([]);

    const result = await offerApi.resources(offer, brand);
    setOfferData(result);
  }

  function handleOfferChange(value) {
    setOffer(value);
  }

  function handleBrandChange(value) {
    setBrand(value);
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      getOfferList().then();

      getBrandList().then();
    }
  }, []);

  useEffect(() => {
    if (offer && brand) fetchOfferData(offer, brand).then();
  }, [offer, brand]);

  return (
    <Page>
      <div>
        <Helmet>
          <title>
            Offers - AllCarParts - download our offers for the spare parts
          </title>
          <meta
            name="description"
            content="Download our offers in machine and human readable file format, by delivery time, whole or separated by individual brands"
          ></meta>
        </Helmet>
      </div>

      <ActionsGroup className={classes.actionsGroup}>
        <Grid container>
          <Grid
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            className={classes.selectFieldContainer}
          >
            <Typography variant="h5" className={classes.searchHeading}>
              {t('delivery_ready_term')}
            </Typography>
            <SelectFine
              items={offerList}
              value={offer}
              classes={{ lang: classes.selectField }}
              icon={calendar_icon}
              onChange={handleOfferChange}
            />
          </Grid>
          <Grid
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            className={classes.selectFieldContainer}
          >
            <Typography variant="h5" className={classes.searchHeading}>
              {t('enter_parts_brand')}
            </Typography>
            <SelectFineTwoCol
              items={brandList}
              value={brand}
              onChange={handleBrandChange}
            />
            <div></div>
            <div></div>
            <div></div>
          </Grid>
        </Grid>
      </ActionsGroup>

      {!isCustomer ? (
        <div className={classes.warnPanel}>
          <Typography className={classes.warnLabel}>
            {isLoggedIn ? (
              <Link to="/bank">{t('offers_complete_registration')}</Link>
            ) : (
              t('offers_log_in')
            )}
          </Typography>
        </div>
      ) : null}

      <div className={classes.tableContainer}>
        <CatalogTable data={offerData} isLoggedIn={isLoggedIn} />
      </div>
    </Page>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.catalogPage;

  const searchContainer = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    padding: `0 ${theme.spacing(1)}px`,
  };

  return {
    actionsGroup: {
      justifyContent: 'space-between',
      minHeight: '125px',
      backgroundColor: '#F2F2F7',
      marginBottom: '30px',
    },
    searchContainer: {
      ...searchContainer,
      justifyContent: 'space-between',
    },
    showButton: {
      marginRight: theme.spacing(1),
    },
    tableContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      height: 'calc(100vh - 275px)',
      width: '95%',
      flexGrow: 1,
      margin: '0 auto',
      overflowX: 'auto',
    },
    warnPanel: {
      borderRadius: '10px',
      backgroundColor: '#F2F2F7',
      textAlign: 'center',
      width: '60%',
      margin: '10px auto',
      minHeight: 30,
      padding: '5px',
    },
    warnLabel: {
      textTransform: 'uppercase',
      '& > *': {
        color: '#FF3B30',
      },
      TextDecorantion: 'none',
    },
    selectField: {
      backgroundColor: '#fff',
      minWidth: '265px',
      height: '32px',
    },
    selectFieldContainer: {
      marginLeft: '60px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
        marginTop: '10px',
        marginBottom: '10px',
      },
    },
    searchHeading: {
      maxWidth: '100%',
      textAlign: 'left',
      fontWeight: '500',
      fontSize: '18px',
      paddingBottom: '12px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '2px',
      },
    },
  };
};

export default withStyles(styles)(CatalogPage);

import http from './http';

export const deliveries = async (onlyActive, onlySorting) => {
  const result = await http.get('/delivery/tariffs', {
    params: { onlyActive: onlyActive, onlyInUse: onlySorting },
  });
  return result.data;
};

export const carriers = async () => {
  const result = await http.get('/delivery/carriers', null);
  return result.data;
};

export const addDelivery = async (data) => {
  try {
    await http.post('/delivery', data);
  } catch (err) {
    throw err;
  }
};

export const modifyDelivery = async (id, data) => {
  try {
    await http.patch('/delivery', data, {
      params: { id: id },
    });
  } catch (err) {
    throw err;
  }
};

export const deliveryDetails = async (id) => {
  try {
    const result = await http.get('/delivery', id);
    return result.data;
  } catch (err) {
    throw err;
  }
};

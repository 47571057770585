import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  withStyles,
  Button,
  Typography,
  Grid,
  Divider,
  Box,
} from '@material-ui/core';
import { newsPublication, newsList } from '../api/news-api';
import Slider from 'react-slick';
import {
  Page,
  SearchBlock,
  CardComponent,
  TimelineComponent,
  SlickSlider,
  HugeCard,
  NewsCard,
  ContactSection,
  Breadcrumb,
} from '../components';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200, // screen width 1000px or less
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800, // screen width 600px or less
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const PageNews = ({ classes }) => {
  const { t, i18n } = useTranslation();
  const { postID } = useParams();
  const [postData, setPostData] = useState(null);
  const [newsCardsData, setNewsCardsData] = useState([]);

  useEffect(() => {
    const fetchNewsList = async () => {
      try {
        const data = await newsList({ lang: i18n.language, fresh: false });
        setNewsCardsData(data);
      } catch (error) {
        console.error('Failed to fetch news list', error);
      }
    };

    fetchNewsList();
  }, [i18n.language]);

  useEffect(() => {
    const fetchNewsPost = async () => {
      try {
        const data = await newsPublication(postID, i18n.language);
        setPostData(data);
      } catch (e) {
        console.log(e.message);
      }
    };
    fetchNewsPost();
  }, [postID, i18n.language]);

  return (
    <Page>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Breadcrumb page="post" title={postData && postData[0].title} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.heading}>
            {postData && postData[0].title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <span>
            <Typography variant="p" className={classes.smallData}>
              {postData &&
                postData[0].date.split('T')[0] + '  •  👁' + postData[0].views}
            </Typography>
          </span>
        </Grid>
        <Grid item xs={12}>
          <span>
            <Typography variant="body1" className={classes.text}>
              {postData &&
                postData[0].text +
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."}
            </Typography>
          </span>
        </Grid>
        <hr style={{ width: '100%' }} />
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" classes={{ h6: classes.footer }}>
              {t('author')}
            </Typography>

            <Typography variant="h6">Евгейний</Typography>
          </Box>
        </Grid>
        <hr style={{ width: '100%' }} />
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" classes={{ h6: classes.footer }}>
              {t('share')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" classes={{ h5: classes.sectionHeading }}>
            {t('other_news')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Slider {...settings} className={classes.SlickSlider}>
            {newsCardsData.slice(0, 3).map((card, index) => (
              <Grid key={index} item className={classes.cards}>
                <NewsCard
                  grey
                  id={card.id}
                  date={card.date.split('T')[0]}
                  content={card.text}
                  subTitle={card.title}
                />
              </Grid>
            ))}
          </Slider>
        </Grid>
      </Grid>
      <ContactSection />
    </Page>
  );
};

const styles = (theme) => ({
  container: {
    margin: '0 auto',
    width: '90%',
  },
  sectionHeading: {
    marginTop: '60px',
    textAlign: 'left',
    fontSize: '26px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  heading: {
    fontSize: '50px',
    fontWeight: 500,
    marginBottom: '30px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px',
    },
  },
  footer: {
    color: '#00000088',
    marginRight: '7px',
  },
  smallData: {
    color: '#00000088',
    fontWeight: 500,
  },
  text: {
    margin: '30px 0',
    fontSize: '20px',
  },
  SlickSlider: {
    display: 'block',
    width: '100%',
    height: 'auto !important' /* Set height to auto */,

    [theme.breakpoints.down('md')]: {
      width: '95%',
      display: 'inline-block !important',
    },
  },
  cards: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingBottom: '2rem',
  },
});

export default withStyles(styles)(PageNews);

import React from 'react';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const CardComponent = ({
  classes,
  content,
  title,
  icon,
  action,
  alignCenter,
}) => {
  return (
    <Card className={classes.root}>
      <CardHeader
        className={alignCenter ? classes.headerAlignCenter : classes.header}
        avatar={<div className={classes.iconContainer}>{icon}</div>}
        title={
          <Typography variant="h6" component="div" className={classes.title}>
            {title}
          </Typography>
        }
      />
      <CardContent>
        <Typography variant="body2" className={classes.content}>
          {content}
        </Typography>
      </CardContent>
      <div className={classes.actions}>
        {action && (
          <>
            {action}
            <NavigateNextIcon style={{ fontSize: '17px' }} />
          </>
        )}
      </div>
    </Card>
  );
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    width: '360px',
    height: '300px',
    borderRadius: '20px',
    backgroundColor: '#F5F5F7',
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: {
      marginTop: '35px',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 'auto',
    alignItems: 'center',
    paddingLeft: '1rem',
    paddingBottom: '1rem',
    '& > *': {
      color: '#007AFF',
      fontSize: '0.85rem',
      fontWeight: 500,
      textTransform: 'capitalize',
      textDecoration: 'none',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  headerAlignCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: '22px',
    lineHeight: '26px',
  },
  content: {
    fontSize: '16px',
    fontWeight: 500,
  },

  iconContainer: {
    backgroundColor: '#007AFF',
    borderRadius: '12.5px',
    width: '50px',
    height: '50px',
    textAlign: 'center',
    '& > *': {
      height: '50px',
      color: '#ffffff',
      fontSize: '35px',
    },
  },
  avatar: {
    width: '50px',
    backgroundColor: '#007AFF',
    borderRadius: '10px',
  },
});

export default withStyles(styles)(CardComponent);

import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { saveExcel } from '../utils/excel';
import { Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import {
  Page,
  BalanceTable,
  BalanceTotalTable,
  ActionsGroup,
  DatePicker,
  BalanceTransferDialog,
  BalanceTopupDialog,
} from '../components';
import * as invoiceApi from '../api/invoice-api';
import * as balanceApi from '../api/balance-api';
import { APP_DATE_FORMAT, SERVER_DATE_FORMAT } from '../utils/date';
import { getCookie } from '../utils/cookies';
import { Redirect } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import download_icon from '../assets/image/download_icon.png';

const BalancePage = ({ classes }) => {
  const [data, setData] = useState([]);
  const [balanceData, setBalanceData] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isBalTransDlgOpen, setBalTransDlgOpen] = useState(false);
  const [isBalTopupDlgOpen, setBalTopupDlgOpen] = useState(false);
  const [isLoggedIn] = useState(!!localStorage.getItem('access_token'));

  const { t, i18n } = useTranslation();

  const isMobile = useMediaQuery('(max-width:600px)');

  const columns = useMemo(
    () => [
      { name: t('date'), width: 10 },
      { name: t('document'), width: 10 },
      { name: t('reference') },
      { name: t('debit'), width: 12 },
      { name: t('credit'), width: 12 },
      { name: t('weight_kg'), width: 11 },
      { name: t('volume_kg'), width: 11 },
    ],
    [i18n.language]
  );

  const outputData = useMemo(
    () =>
      data.map((value) => [
        moment(value.aDate).format(APP_DATE_FORMAT),
        value.document,
        value.aNumber,
        value.debet,
        value.credit,
        value.grossKG,
        value.volumeKG,
      ]),
    [data]
  );

  async function fetchData() {
    const result = await invoiceApi.invoices({
      fromDate: startDate ? startDate.format(SERVER_DATE_FORMAT) : null,
      tillDate: endDate ? endDate.format(SERVER_DATE_FORMAT) : null,
    });
    setData(result);
  }

  useEffect(() => {
    if (isLoggedIn) {
      fetchBalanceData();
      fetchData();
    }
  }, []);

  async function fetchBalanceData() {
    const result = await balanceApi.totals();
    setBalanceData(result);
  }

  useEffect(() => {
    if (isLoggedIn) fetchBalanceData();
  }, []);

  function handleBalanceExport() {
    saveExcel('Balance', columns, outputData);
  }

  const invoiceExportColumns = useMemo(() => [
    { name: 'Row', width: 5 },
    { name: 'Brand', width: 15 },
    { name: 'PartNumber', width: 15 },
    { name: 'Description', width: 20 },
    { name: 'Quantity', width: 10 },
    { name: 'Price', width: 10 },
    { name: 'Amount', width: 10 },
    { name: 'TaxRate', width: 10 },
    { name: 'TaxAmount', width: 10 },
    { name: 'Currency', width: 10 },
    { name: 'SubstNumber', width: 10 },
    { name: 'SubstBrand', width: 10 },
    { name: 'Origin', width: 10 },
    { name: 'HSCode', width: 12 },
    { name: 'WeightKG', width: 10 },
    { name: 'VolumeKG', width: 10 },
    { name: 'Box', width: 10 },
    { name: 'Portion', width: 10 },
    { name: 'Reference', width: 15 },
    { name: 'Order', width: 10 },
  ]);

  async function handleInvoiceExport(invoice) {
    try {
      const details = await invoiceApi.invoiceDetails(invoice.id);

      if (!details.length) return;

      const data = details.map((value) => [
        value.iRow,
        value.brand,
        value.partNumber,
        value.description,
        value.quantity,
        value.price,
        value.amount,
        value.rateTax,
        value.amountTax,
        value.currency,
        value.substitutedNumber,
        value.substitutedBrand,
        value.country,
        value.customsCode,
        value.weightKg,
        value.volumeKg,
        value.box,
        value.portion,
        value.reference,
        value.orderId,
      ]);

      saveExcel(`Invoice_${invoice.aNumber}`, invoiceExportColumns, data);
    } catch {
      alert(t('cannot_export_invoice'));
    }
  }

  function handleFilterData() {
    fetchData();
  }

  function toggleBalTransDlg() {
    //setBalTransDlgOpen(!isBalTransDlgOpen);
    window.location.href = '/bank';
  }

  function toggleBalTopupDlg() {
    setBalTopupDlgOpen(!isBalTopupDlgOpen);
  }

  return (
    <Page>
      <div>
        <Helmet>
          <title>Balance - AllCarParts - control your balance with us</title>
          <meta
            name="description"
            content="Control your balance and statements with us, download invoices, add money on your account"
          ></meta>
        </Helmet>
      </div>

      {!isLoggedIn && <Redirect to="/search" />}
      <div className={classes.centerContainer}>
        <BalanceTotalTable data={balanceData} />
      </div>
      <ActionsGroup className={classes.actionsGroup}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.actionHeading}>
              {t('transaction_history')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.actionSubHeading}>
              {t('specify_period')}
            </Typography>
          </Grid>
          <Grid item xs={12} container>
            <Grid item md={12} className={classes.balanceActionsLeft}>
              <DatePicker
                className={classes.datePicker}
                classes={{
                  input: classes.datePickerInput,
                }}
                label={t('start_date')}
                value={startDate}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={setStartDate}
              />
              <DatePicker
                className={classes.datePicker}
                classes={{
                  input: classes.datePickerInput,
                }}
                label={t('end_date')}
                value={endDate}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={setEndDate}
              />
              {isMobile ? null : (
                <Button
                  variant="contained"
                  className={classes.showButton}
                  onClick={handleFilterData}
                >
                  {t('show')}
                </Button>
              )}
            </Grid>

            {isMobile ? (
              <Grid xs={12}>
                <Button
                  variant="contained"
                  className={classes.showButtonXs}
                  onClick={handleFilterData}
                >
                  {t('show')}
                </Button>
              </Grid>
            ) : (
              <Grid item md={2} className={classes.balanceActionsRight}>
                <Button
                  variant="contained"
                  className={classes.exportButton}
                  onClick={handleBalanceExport}
                >
                  <img
                    className={classes.excelLogo}
                    alt=""
                    src={download_icon}
                  />
                  {t('export')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* <div className={classes.balanceActionsLeft}>
        </div>
        {balanceData && false && (
          <Button
            variant="contained"
            color="primary"
            className={classes.topUpBalanceButton}
            onClick={toggleBalTopupDlg}
          >
            {t('topup_online')}
          </Button>
        )}
        <a href="/bank">
          <Button variant="contained" className={classes.bankTransferButton}>
            {t('bank_transfer')}
          </Button>
        </a> */}
      </ActionsGroup>
      <div className={classes.tableContainer}>
        <BalanceTable
          columns={columns}
          data={outputData}
          originalData={data}
          onInvoiceExport={handleInvoiceExport}
        />
      </div>
      <BalanceTransferDialog
        open={isBalTransDlgOpen}
        onClose={toggleBalTransDlg}
      />
      <BalanceTopupDialog
        open={isBalTopupDlgOpen}
        onClose={toggleBalTopupDlg}
        balanceData={balanceData}
        isLoggedIn={isLoggedIn}
      />
    </Page>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.balancePage;
  const balanceActionsItem = {
    marginRight: theme.spacing(1),
  };
  return {
    actionsGroup: {
      padding: '20px 16px',
      margin: '0 auto 1rem auto',
      width: '90%',
      borderRadius: '20px',
      background: '#F2F2F7',
      [theme.breakpoints.up('xl')]: {
        width: '95%',
      },
    },
    actionHeading: {
      fontSize: '18px',
      margin: '10px 0',
    },
    actionSubHeading: {
      fontWeight: 400,
      fontSize: '16px',
      marginBottom: '10px',
    },
    centerContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '24px',
    },
    buttonsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 24px',
    },
    balanceActionsLeft: {
      flex: '1 0 0px',
      display: 'flex',
      alignItems: 'end',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-between',
      },
    },
    balanceActionsRight: {
      flex: '1 0 0px',
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'flex-end',
    },
    exportButton: {
      ...theme.button.transparent,
      height: '32px',
      borderRadius: '10px',
    },
    balanceActionsItem,
    datePicker: {
      ...balanceActionsItem,
      maxWidth: '152px',
    },
    datePickerInput: {
      height: '32px',
      backgroundColor: '#FFFFFF',
      borderRadius: '10px',
      padding: '8px 12px',
    },
    showButton: {
      ...theme.button.filled,
      height: '32px',
      borderRadius: '10px',
    },
    showButtonXs: {
      ...theme.button.filled,
      height: '32px',
      borderRadius: '10px',
      width: '100%',
      margin: '10px auto',
    },
    topUpBalanceButton: {
      ...balanceActionsItem,
      marginRight: theme.spacing(1),
    },
    bankTransferButton: {
      ...balanceActionsItem,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    excelButton: {
      backgroundColor: colors.excelButton.default,
      color: colors.excelButton.fontColor,
      '&:hover': {
        backgroundColor: colors.excelButton.hovered,
      },
    },
    excelLogo: {
      width: '16px',
      height: '16px',
      marginRight: theme.spacing(1),
    },
    tableContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      height: 'calc(100vh - 275px)',
      width: '100%',
      flexGrow: 1,
      margin: '0 auto',
      overflowX: 'auto',
    },
  };
};

export default withStyles(styles)(BalancePage);

import React, { useState } from 'react';
import { resetPassword } from '../api/user-api';

import {
  Button,
  Paper,
  TextField,
  withStyles,
  Grid,
  Typography,
  Chip,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { Page } from '../components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import img_header from '../assets/image/image_header_reg.png';
import ErrorIcon from '@material-ui/icons/Error';

function ResetPasswordPage({ classes, location, history }) {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  function handleChange(event) {
    event.target.name === 'password'
      ? setPassword(event.target.value)
      : setConfirmPassword(event.target.value);
  }
  const validatePassword = (password) => {
    // Check the length of the password
    if (password.length < 8) {
      alert('Password must be at least 8 characters');
      return false;
    }

    // Check for special character
    if (!/[!@#$%^&*(),.?":{}|<>]/g.test(password)) {
      alert('Password must contain at least one special character');
      return false;
    }

    // Check for a digit
    if (!/\d/.test(password)) {
      alert('Password must contain at least one digit');
      return false;
    }

    return true;
  };

  async function handleReset() {
    if (!validatePassword(password)) {
      setError(t('password_requiremests'));
      return;
    }
    if (password !== confirmPassword) {
      setError(t('error_password_not_match'));
      return;
    }
    try {
      setSubmitting(true);
      await resetPassword(password, location.search);
      history.replace('/search');
      setSubmitting(false);
    } catch (err) {
      alert('Cannot reset password due to error');
      setSubmitting(false);
    }
  }

  const disabled =
    isSubmitting || password.length < 8 || confirmPassword.length < 8;

  return (
    <Page>
      <Grid container justifyContent="center">
        <Grid xs={12} item>
          <div className={classes.header}>
            <Grid item className={classes.headerCell}>
              <Typography variant="h5" className={classes.headerTitle}>
                {t('header_log_in')}
              </Typography>
              <Typography variant="p" className={classes.headerSubTitle}>
                {t('header_first_time_here')}
              </Typography>
              <Link to="/register">
                <Button className={classes.headerButton}>
                  {t('tab_register')}
                </Button>
              </Link>
            </Grid>
          </div>
        </Grid>
        <Grid item className={classes.formContainer}>
          <form className={classes.form}>
            {!!error && (
              <Chip
                className={classes.errorChip}
                color="secondary"
                icon={<ErrorIcon />}
                label={error}
              />
            )}
            <Typography variant="h5" className={classes.searchHeading}>
              {t('reset_password')}
            </Typography>
            <Typography variant="p" className={classes.textLabel}>
              {t('new_password')}
            </Typography>
            <TextField
              name="password"
              className={classes.textField}
              type="password"
              autoComplete="new-password"
              variant="outlined"
              placeholder={'••••••••'}
              margin="normal"
              fullWidth
              value={password}
              onChange={handleChange}
            />
            <List className={classes.list}>
              <ListItem className={classes.list}>
                <ListItemText
                  classes={{ root: classes.listTextRoot }}
                  primary={t('password_requiremests_head')}
                />
              </ListItem>
              <ListItem className={classes.list}>
                <ListItemText
                  classes={{ root: classes.listTextRoot }}
                  primary={t('password_at_least_chars')}
                />
              </ListItem>
              <ListItem className={classes.list}>
                <ListItemText
                  classes={{ root: classes.listTextRoot }}
                  primary={t('password_at_least_special')}
                />
              </ListItem>
              <ListItem className={classes.list}>
                <ListItemText
                  classes={{ root: classes.listTextRoot }}
                  primary={t('password_at_least_digit')}
                />
              </ListItem>
            </List>
            <Typography variant="p" className={classes.textLabel}>
              {t('confirm_password')}
            </Typography>
            <TextField
              name="confirmPassword"
              className={classes.textField}
              type="password"
              autoComplete="new-password"
              variant="outlined"
              placeholder={'••••••••'}
              margin="normal"
              fullWidth
              value={confirmPassword}
              onChange={handleChange}
            />
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              disabled={disabled}
              onClick={handleReset}
            >
              {t('submit')}
            </Button>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
}

const styles = (theme) => {
  const colors = theme.palette.custom.resetPasswordPage;
  const colorsL = theme.palette.custom.loginDialog;
  return {
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: colors.dialog,
      padding: '24px',
      width: '400px',
      boxShadow: 'none',
    },
    button: {
      marginTop: theme.spacing(1) * 2,
      width: '100%',
    },
    textField: {
      '& > *': {
        borderRadius: '10px',
        height: '40px',
        width: '280px',
      },
    },

    formContainer: {
      boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.10)',
      padding: '30px',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '345px',
      margin: '0 auto',
      [theme.breakpoints.up('xl')]: {
        margin: '0 auto',
      },
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    list: {
      color: '#00000080',
      fontSize: '8px',
      padding: '0',
      margin: '0',
    },
    listTextRoot: {
      padding: '0',
      margin: '0',
      '& > *': {
        fontSize: '10px !important',
      },
    },
    header: {
      margin: '60px auto',
      height: '192px',
      width: '85%',
      borderRadius: '20px',
      padding: '0',
      overflow: 'hidden',
      backgroundImage: `url(${img_header})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        borderRadius: '0px',
      },
      [theme.breakpoints.up('xl')]: {
        width: '80%',
      },
    },
    errorChip: {
      color: '#ffffff',
      fontWeight: '500',
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(1) * 2,
    },
    headerCell: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 'clamp(340px,30%,50%)',
      height: '100%',
      backdropFilter: 'blur(20px)',
      padding: '30px 5px 15px 30px',
    },
    headerTitle: {
      fontWeight: '500',
      fontSize: '40px',
      fontWeight: '600',
      color: '#FFFFFF',
      textAlign: 'left',
      paddingBottom: '10px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '2px',
      },
    },
    headerSubTitle: {
      color: '#FFFFFF',
      fontWeight: '400',
      fontSize: '12px',
    },
    headerButton: {
      color: '#FFFFFF',
      minWidth: '80px',
      height: '24px',
      background: '#ffffff40',
      fontSize: '12px',
      margin: '10px 0',
      borderRadius: '100px',
      fontWeight: '400',
      textTransform: 'capitalize',
    },
    searchHeading: {
      fontWeight: '500',
      fontSize: '22px',
      textAlign: 'center',
      paddingBottom: '15px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '2px',
      },
    },
  };
};

export default withStyles(styles)(ResetPasswordPage);

import React from 'react';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import huge_card_bg from '../assets/image/huge_card_bg.png';

const HugeCard = ({ classes, content, title, icon, wide }) => {
  return (
    <Card className={wide ? classes.rootWide : classes.root}>
      <CardHeader
        className={classes.header}
        avatar={<div className={classes.iconContainer}>{icon}</div>}
        title={
          <Typography variant="h6" component="div" className={classes.title}>
            {title}
          </Typography>
        }
      />
      <CardContent></CardContent>
      <div className={classes.actions}>
        <Typography variant="body2" className={classes.content}>
          {content}
        </Typography>
        <div className={classes.decor}></div>
      </div>
    </Card>
  );
};

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${huge_card_bg})`,
    backgroundSize: '260%',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '48%',
    backgroundPositionX: '66%',
    display: 'flex',
    flexDirection: 'column',
    padding: '1.75rem',
    width: '545px',
    height: '690px',
    borderRadius: '20px',
    backgroundColor: '#F5F5F7',
    boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.15)',
    [theme.breakpoints.down('sm')]: {
      marginTop: '35px',
      width: '360px',
      height: '418px',
    },
  },

  rootWide: {
    backgroundImage: `url(${huge_card_bg})`,
    backgroundSize: '160%',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '46%',
    backgroundPositionX: '66%',
    display: 'flex',
    flexDirection: 'column',
    padding: '1.75rem',
    width: '1140px',
    height: '616px',
    borderRadius: '20px',
    backgroundColor: '#F5F5F7',
    boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.15)',
    [theme.breakpoints.down('md')]: {
      backgroundSize: '260%',
      marginTop: '35px',
      width: '545px',
      height: '690px',
      backgroundPositionY: '48%',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '35px',
      width: '360px',
      height: '418px',
    },
  },

  actions: {
    backgroundColor: 'rgba(255, 255, 255, 0.5);',
    backdropFilter: 'blur(15px)',
    borderRadius: '10px',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 'auto',
    alignItems: 'center',
    padding: '1.5rem',
    overflow: 'hidden',
    '& > *': {
      color: '#000000',
      fontSize: '0.85rem',
      fontWeight: 500,
      textTransform: 'capitalize',
      textDecoration: 'none',
    },
  },

  decor: {
    position: 'absolute',
    backgroundColor: '#007AFF',
    width: '50px',
    height: '50px',
    borderRadius: '30px 0px 0px 0px',
    right: '-10px',
    bottom: '-10px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontSize: '22px',
  },
  content: {
    fontSize: '16px',
    fontWeight: 500,
  },

  iconContainer: {
    backgroundColor: '#007AFF',
    borderRadius: '12.5px',
    width: '50px',
    height: '50px',
    textAlign: 'center',
    '& > *': {
      height: '50px',
      color: '#ffffff',
      fontSize: '35px',
    },
  },
  avatar: {
    width: '50px',
    backgroundColor: '#007AFF',
    borderRadius: '10px',
  },
});

export default withStyles(styles)(HugeCard);

import http from './http';

export const newsList = async ({ lang = 'en', fresh = false }) => {
  return [];

  const result = await http.get('news/news', {
    params: { lang: lang, news: fresh },
  });
  return result.data;
};

export const newsPublication = async (id, lang = 'en') => {
  return undefined;

  try {
    const result = await http.get(`/news/news/${id}/data`, {
      params: { lang: lang, id: id },
    });
    return result.data;
  } catch (error) {}
};

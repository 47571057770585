import React, { useEffect, useState } from 'react';
import {
  withStyles,
  withWidth,
  isWidthDown,
  Grid,
  AppBar,
} from '@material-ui/core';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import { Tabs, Tab } from '@material-ui/core';

import { Page } from '../components';

const ManualsPage = ({ width, classes, history, location }) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const [manSignUp, setManSignUp] = useState('');
  const [manOffers, setManOffers] = useState('');
  const [manOrders, setManOrders] = useState('');
  const [manQuote, setManQuote] = useState('');
  const [manApi, setManApi] = useState('');
  const [tab, setTab] = useState(0);
  const isMobile = isWidthDown('xs', width);

  function handleTabChange(event, value) {
    setTab(value);
  }

  useEffect(() => {
    const fetchManSignUp = async () => {
      const result = await axios.get(
        `/manuals/${i18n.language}/manSignUp.html`
      );
      setManSignUp(result.data);
    };
    fetchManSignUp();

    const fetchManOffers = async () => {
      const result = await axios.get(
        `/manuals/${i18n.language}/manOffers.html`
      );
      setManOffers(result.data);
    };
    fetchManOffers();

    const fetchManQuote = async () => {
      const result = await axios.get(`/manuals/${i18n.language}/manQuote.html`);
      setManQuote(result.data);
    };
    fetchManQuote();

    const fetchManOrders = async () => {
      const result = await axios.get(
        `/manuals/${i18n.language}/manOrders.html`
      );
      setManOrders(result.data);
    };
    fetchManOrders();

    const fetchManApi = async () => {
      const result = await axios.get(`/manuals/${i18n.language}/manApi.html`);
      setManApi(result.data);
    };
    fetchManApi();
  }, [i18n.language]);

  return (
    <Page>
      <div>
        <Helmet>
          <title>Manuals - All Car Parts - find out how to work with us</title>
          <meta
            name="description"
            content="How to search and order spare parts, track your orders, pay your balance, control delivery, integrate with us through API, and many more"
          ></meta>
        </Helmet>
      </div>
      <Grid container justifyContent="center" style={{ background: '#F2F2F7' }}>
        <Grid item xs={12} style={{ margin: '10px 0' }}>
          <Tabs
            orientation={isMobile ? 'vertical' : 'horizontal'}
            classes={{
              root: classes.tabs,
              indicator: classes.indicator,
              flexContainer: classes.tabContainer,
              flexContainerVertical: classes.tabContainer,
            }}
            value={tab}
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: {
                marginLeft: tab === 0 && !isMobile ? '2px' : '-2px',
                marginTop: tab === 0 && isMobile ? '2px' : '-2px',
              },
            }}
          >
            <Tab
              disableRipple
              label={t('register')}
              classes={{ root: classes.tabRoot, wrapper: classes.typography }}
              style={{
                borderRadius: isMobile ? '10px 10px 0 0' : '10px 0 0 10px',
              }}
            />
            <Tab
              disableRipple
              label={t('quotation')}
              classes={{ root: classes.tabRoot, wrapper: classes.typography }}
              style={{}}
            />
            <Tab
              label={t('man_orders')}
              disableRipple
              classes={{ root: classes.tabRoot, wrapper: classes.typography }}
              style={{}}
            />
            <Tab
              label={t('offers')}
              disableRipple
              classes={{ root: classes.tabRoot, wrapper: classes.typography }}
              style={{}}
            />
            <Tab
              label={t('api')}
              disableRipple
              classes={{ root: classes.tabRoot, wrapper: classes.typography }}
              style={{
                borderRadius: isMobile ? '0 0 10px 10px' : '0 10px 10px 0',
              }}
            />
          </Tabs>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" style={{ padding: '10px' }}>
        {tab === 0 ? (
          <div>
            <div dangerouslySetInnerHTML={{ __html: manSignUp }} />
          </div>
        ) : tab === 1 ? (
          <div dangerouslySetInnerHTML={{ __html: manQuote }} />
        ) : tab === 2 ? (
          <div dangerouslySetInnerHTML={{ __html: manOrders }} />
        ) : tab === 3 ? (
          <div>
            <div dangerouslySetInnerHTML={{ __html: manOffers }} />
          </div>
        ) : (
          <div>
            <div dangerouslySetInnerHTML={{ __html: manApi }} />
          </div>
        )}
      </Grid>
    </Page>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.searchPage;
  return {
    tabs: {
      width: '100%',
      minHeight: '32px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: '0 auto',
        padding: '2px 0',
      },
    },
    tabRoot: {
      backgroundColor: '#7676801F',
      opacity: 1,
      minHeight: '32px',
      width: '190px',
      minWidth: '40px',
      [theme.breakpoints.up('md')]: {
        textWrap: 'nowrap',
      },
      [theme.breakpoints.down('sm')]: {
        width: '120px',
        height: '42px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '42px',
        maxWidth: '355px',
      },
    },
    tabSelected: {
      color: '#000',
    },
    tabContainer: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    indicator: {
      marginBottom: '2px',
      zIndex: '0',
      height: '88%',
      borderRadius: '10px',
      backgroundColor: '#fff',
      transition: 'left 0.3s',
      boxShadow:
        '0px 2.25px 6px rgba(0, 0, 0, 0.12), 0px 2.25px 0.75px rgba(0, 0, 0, 0.04)',
      [theme.breakpoints.down('xs')]: {
        transition: 'top 0.3s',
        left: '21%',
        right: '20%',
        width: '59%',
        //margin: '2px auto',
        height: '40px !important',
        maxWidth: '355px',
      },
    },

    dialog: {
      overflowY: 'auto',
      height: '70vh',
    },

    typography: {
      color: '#000',
      zIndex: 1000,
      fontWeight: 500,
      fontSize: '12px',
      ...theme.typography,
    },

    iframe: {
      border: 0,
      width: '100%',
      height: '99%',
    },
  };
};

export default withWidth()(withStyles(styles)(ManualsPage));

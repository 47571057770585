import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Box,
  Divider,
  Typography,
  ListItemIcon,
  MenuItem,
  InputLabel,
  FormControl,
  TextField as MuiSelect,
  ListSubheader,
} from '@material-ui/core';

import car_icon from '../assets/image/car_icon.png';

const SelectFineTwoCol = ({
  classes,
  items,
  value,
  label,
  textColor,
  labelColor,
  variant,
  onChange,
  icon,
}) => {
  function handleChange(event) {
    onChange(event.target.value);
  }

  const sortedItems = items.sort((a, b) => a.title.localeCompare(b.title));

  const groupedItems = sortedItems.reduce((grouped, item) => {
    const key = /^[a-zA-Z]/.test(item.title[0])
      ? item.title[0].toUpperCase()
      : 'Other';
    if (!grouped[key]) grouped[key] = [];
    grouped[key].push(item);
    return grouped;
  }, {});

  const sortedGroups = Object.entries(groupedItems).sort(([groupA], [groupB]) =>
    groupA === 'Other'
      ? 1
      : groupB === 'Other'
      ? -1
      : groupA.localeCompare(groupB)
  );

  function flattenGroupedItems(groupedItems) {
    let flattenedItems = [];

    Object.keys(groupedItems)
      .sort()
      .forEach((key) => {
        // Add the heading item
        flattenedItems.push({ title: key, value: 'eading00' });

        // Add the associated items
        groupedItems[key].forEach((item) => {
          flattenedItems.push(item);
        });
      });

    // Move the "Other" group to the end if it exists
    if (flattenedItems.find((item) => item.title === 'Other')) {
      const otherIndex = flattenedItems.findIndex(
        (item) => item.title === 'Other'
      );
      const otherItems = flattenedItems.splice(
        otherIndex,
        groupedItems['Other'].length + 1
      );
      flattenedItems = [...flattenedItems, ...otherItems];
    }

    return flattenedItems;
  }

  const flattenedItems = flattenGroupedItems(groupedItems);

  return (
    <MuiSelect
      select
      value={value}
      variant={variant}
      onChange={handleChange}
      className={classes.lang}
      SelectProps={{
        IconComponent: (props) => <ExpandMoreIcon style={{ fontSize: 20 }} />,
        renderValue: (selected) => (
          <Box className={classes.langContainer}>
            <ListItemIcon className={classes.iconContainer}>
              <img src={car_icon} className={classes.icon} />
            </ListItemIcon>
            <Typography variant="body1" className={classes.iconText}>
              | {items.find((item) => item.value === selected)?.title}
            </Typography>
          </Box>
        ),
        MenuProps: {
          classes: {
            list: classes.dropDownList,
          },
        },
      }}
      InputProps={{
        style: {
          fontSize: 'small',
          color: { textColor },
          fontWeight: 500,
        },
      }} // font size of input text
      InputLabelProps={{
        style: {
          fontSize: 'medium',
          color: { labelColor },
        },
      }} // font size of label text
    >
      {flattenedItems.map((item) =>
        item.value === 'eading00' ? (
          <Box
            display="flex"
            alignItems="center"
            my={1}
            style={{ width: '80%' }}
          >
            <Box px={2}>
              <Typography variant="body1">{item.title}</Typography>
            </Box>
            <Box flex="1">
              <Divider />
            </Box>
          </Box>
        ) : (
          <MenuItem key={item.value} value={item.value}>
            <Box className={classes.langContainer}>
              <ListItemIcon className={classes.iconContainer}></ListItemIcon>
              <Typography variant="body1" className={classes.iconText}>
                {item.title}
              </Typography>
            </Box>
          </MenuItem>
        )
      )}
    </MuiSelect>
  );
};

const styles = (theme) => ({
  input: {
    backgroundColor: '#fff',
  },
  langContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    root: {
      columns: 2,
    },
  },
  lang: {
    borderRadius: '10px',
    backgroundColor: '#fff',
    minWidth: '265px',
    height: '32px',
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:after': {
      borderBottomColor: '#fff',
    },
    '&:hover': {
      borderBottom: 'none',
      backgroundColor: '#b6b8ba',
    },
  },
  iconText: {
    lineHeight: '20px',
  },
  iconContainer: {
    minWidth: '15px',
  },
  dropDownList: {
    columns: '2',
  },
  icon: {
    height: '14px',
    width: '14px',
    marginTop: '3px',
    marginRight: '5px',
    marginLeft: '10px',
  },
});
export default withStyles(styles)(SelectFineTwoCol);

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import download_icon from '../assets/image/download_icon.png';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from '@material-ui/core';

const BalanceTable = ({
  classes,
  columns,
  data,
  originalData,
  onInvoiceExport,
}) => {
  return (
    <TableContainer
      component={Paper}
      variant="rounded"
      className={classes.tableContainer}
    >
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHead}>
            {columns.map((col) => (
              <TableCell key={col.name}>{col.name}</TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <TableRow
              key={i}
              style={{ backgroundColor: i % 2 === 0 ? '#FBFBFB' : '#FFFFFF' }}
            >
              {row.map((cell, j) => (
                <TableCell key={j}>{cell}</TableCell>
              ))}
              <TableCell>
                {row[1] === 'invoice' && (
                  <Button
                    className={classes.excelButton}
                    size="small"
                    variant="contained"
                    onClick={onInvoiceExport.bind(null, originalData[i])}
                  >
                    <img
                      className={classes.excelLogo}
                      alt=""
                      src={download_icon}
                    />
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const styles = (theme) => {
  return {
    table: {
      borderRadius: '10px',
    },
    tableContainer: {
      margin: '0 auto',
      width: '90%',
      boxShadow: 'none',
      border: '1px solid #00000040',
      display: 'flex',
      maxHeight: '100%',
      overflowY: 'auto',
      borderRadius: '10px',
      [theme.breakpoints.up('xl')]: {
        width: '95%',
      },
    },
    tableHead: {
      backgroundColor: '#F2F2F7',
    },
    excelButton: {
      ...theme.button.transparent,
      width: '50px',
      height: '28px',
    },
    excelLogo: {
      width: '16px',
      height: '16px',
    },
  };
};

export default withStyles(styles)(BalanceTable);

import React, { useEffect, useState } from 'react';
import { Page } from '../components';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const BankPage = () => {
  const { i18n } = useTranslation();

  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchContent = async () => {
      const result = await axios.get(`/manuals/${i18n.language}/bank.html`);
      setContent(result.data);
    };
    fetchContent();
  }, [i18n.language]);
  return (
    <Page>
      <div>
        <Helmet>
          <title>Bank details - AllCarParts - our bank details</title>
          <meta
            name="description"
            content="Find out how you can add money on your account, using bank transfers"
          ></meta>
        </Helmet>
      </div>

      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Page>
  );
};

export default BankPage;

import React, { useState, useEffect } from 'react';
import * as userApi from '../api/user-api';
import * as dictionaryApi from '../api/dictionary-api';
import { getCookie } from '../utils/cookies';
import { setCookie } from '../utils/cookies';
import {
  PageHeader,
  PageTabs,
  PageContent,
  PageFooter,
  LoginDialog,
  SignUpDialog,
  UserDataDialog,
} from '../components';
import { withStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import SlickSlider from './SlickSlider';

const Page = ({ classes, children, render }) => {
  const { t } = useTranslation();
  const [languageCode, setLanguageCode] = useState(
    localStorage.getItem('languageCode') || 'en'
  );

  const [isLoginDialogOpen, setLoginDialogOpen] = useState(false);
  const [isSignUpDialogOpen, setSignUpDialogOpen] = useState(false);
  //const [isLoggedIn, setIsLoggedIn] = useState(!!getCookie('access_token'));
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem('access_token')
  );
  const [loginError, setLoginError] = useState('');
  const [signUpError, setSignUpError] = useState('');
  const [passResetError, setPassResetError] = useState('');
  const [isPasswordReset, setPasswordReset] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const [isUserDataDialogOpen, setUserDataDialogOpen] = useState(false);
  const [isUserDataSubmitting, setUserDataSubmitting] = useState(false);
  const [submitUserDataError, setSubmitUserDataError] = useState('');

  const [countryList, setCountryList] = useState([]);

  const brandImages = [
    '/images/brands/bmw.png',
    '/images/brands/audi.jpg',
    '/images/brands/ford.png',
    '/images/brands/honda.png',
    '/images/brands/hyundai.jpg',
    '/images/brands/mazda.png',
    '/images/brands/mercedes.jpg',
    '/images/brands/mitsubishi.png',
    '/images/brands/nissan.png',
    '/images/brands/porsche.jpg',
    '/images/brands/suzuki.png',
    '/images/brands/subaru.jpg',
    '/images/brands/toyota.jpg',
  ];

  const languageList = [
    {
      value: 'en',
      title: 'Eng',
    },
    {
      value: 'ru',
      title: 'Руc',
    },
    {
      value: 'uk',
      title: 'Укр',
    },
    {
      value: 'ar',
      title: 'Arb',
    },
  ];

  const languageSelect = (code) => {
    localStorage.setItem('languageCode', code);
    setLanguageCode(code);
    i18n.changeLanguage(code);
  };

  async function getCountryList() {
    setCountryList([]);
    const result = await dictionaryApi.countries();

    setCountryList(
      result.map((row) => ({
        title: row['name'],
        value: row['code'],
      }))
    );
  }

  const [currencyList, setCurrencyList] = useState([]);

  async function getCurrencyList() {
    setCurrencyList([]);
    const result = await dictionaryApi.currencies();

    setCurrencyList(
      result.map((row) => ({
        title: row['name'],
        value: row['code'],
      }))
    );
  }

  const [userData, setUserData] = useState({
    username: '',
    countryCode: '',
    zipCode: '',
    city: '',
    state: '',
    street: '',
    building: '',
    flat: '',
    phone: '',
    contactPerson: '',
    contactPhone: '',
    contactEMail: '',
    trn: '',
    currencyCode: '',
    customerCode: '',
    isCustomer: false,
    isSupplier: false,
  });

  useEffect(() => {
    const checkLogin = setInterval(() => {
      //  isLoggedIn !== !!getCookie('access_token') &&
      isLoggedIn !== !!localStorage.getItem('access_token') &&
        setIsLoggedIn(!isLoggedIn);
    }, 500);

    try {
      getUserData();
      getCountryList();
      getCurrencyList();
    } catch (error) {
      // silence
    }

    return () => clearInterval(checkLogin);
  }, [isLoggedIn]);

  function handleLoginDialogClose() {
    setLoginDialogOpen(false);
  }

  function handleLoginDialogOpen() {
    setLoginDialogOpen(true);
  }

  async function getUserData() {
    setUserData({
      username: '',
      countryCode: '',
      zipCode: '',
      city: '',
      state: '',
      street: '',
      building: '',
      flat: '',
      phone: '',
      email: '',
      contactPerson: '',
      contactPhone: '',
      contactEMail: '',
      trn: '',
      currencyCode: '',
      customerCode: '',
      isCustomer: false,
      isSupplier: false,
    });

    const result = await userApi.getData();

    if (result !== null && result.length !== 0) {
      setCookie('currency', result[0].currencyCode);

      localStorage.setItem(
        'is_customer',
        result[0].isCustomer ? 'true' : 'false'
      );

      setUserData({
        username: result[0].username,
        countryCode: result[0].countryCode,
        zipCode: result[0].zipCode,
        city: result[0].city,
        state: result[0].state,
        street: result[0].street,
        building: result[0].building,
        flat: result[0].flat,
        phone: result[0].phone,
        email: result[0].email,
        contactPerson: result[0].contactPerson,
        contactPhone: result[0].contactPhone,
        contactEMail: result[0].contactEMail,
        trn: result[0].trn,
        currencyCode: result[0].currencyCode,
        customerCode: result[0].customerCode,
        isCustomer: result[0].isCustomer,
        isSupplier: result[0].isSupplier,
      });
    }
  }

  async function handleLogin(email, password) {
    try {
      setSubmitting(true);
      await userApi.login(email, password);
      setLoginDialogOpen(false);
      setSubmitting(false);
      window.location.reload();
    } catch (error) {
      setLoginError(error.message);
      setSubmitting(false);
    }
  }

  async function handleSignUp(user) {
    try {
      setSubmitting(true);

      const { currencyCode } = user;
      delete user.currencyCode;

      await userApi.signUp(user);
      setSignUpDialogOpen(false);

      try {
        await userApi.login(user.email, user.password);

        if (currencyCode) {
          const ud = userApi.getData();
          if (ud.currencyCode !== currencyCode) {
            ud.currencyCode = currencyCode;
            await userApi.setData(ud);
          }
        }
      } catch (error) {
        setLoginError(error.message);
      }

      setSubmitting(false);

      window.location.reload();
    } catch (error) {
      setSignUpError(error.message);
      setSubmitting(false);
    }
  }

  async function handleSubmitUserData(data) {
    try {
      setUserDataSubmitting(true);
      await userApi.setData(data);
      setUserDataDialogOpen(false);
      setUserDataSubmitting(false);
      window.location.reload();
    } catch (error) {
      setSubmitUserDataError(error.message);
      setUserDataSubmitting(false);
    }
  }

  function handleSignUpDialogClose() {
    setSignUpDialogOpen(false);
  }

  function handleSignUpDialogOpen() {
    setSignUpDialogOpen(true);
  }

  function handleUserDataDialogOpen() {
    setUserDataDialogOpen(true);
  }

  function handleUserDataDialogClose() {
    setUserDataDialogOpen(false);
  }

  async function handleLogout() {
    try {
      await userApi.logout();
      window.location.reload();
    } catch (error) {
      // empty
    }
  }

  async function handlePasswordReset(email) {
    try {
      await userApi.reset(email);
      setPasswordReset(true);
    } catch (err) {
      setPassResetError(err.message);
    }
  }
  return (
    <div className={classes.page}>
      <PageHeader
        companyName={window.COMPANY_NAME}
        companyAddress={window.COMPANY_ADDRESS}
        customerCode={userData.customerCode}
        isLoggedIn={isLoggedIn}
        onLogin={handleLoginDialogOpen}
        onSignUp={handleSignUpDialogOpen}
        onLogout={handleLogout}
        onUserData={handleUserDataDialogOpen}
        languageCode={languageCode}
        languageList={languageList}
        languageSelect={languageSelect}
      />
      {1 == 0 && <PageTabs isLoggedIn={isLoggedIn} />}
      <PageContent>{render ? render({ isLoggedIn }) : children}</PageContent>

      {/* {!isLoggedIn && (
        <div className={classes.slideContainer}>
         <SlickSlider items={brandImages} />
        </div>
      )} */}

      <PageFooter />

      {isLoginDialogOpen && (
        <LoginDialog
          error={loginError || passResetError}
          isSubmitting={isSubmitting}
          isPasswordReset={isPasswordReset}
          onClose={handleLoginDialogClose}
          onLogin={handleLogin}
          onPasswordReset={handlePasswordReset}
        />
      )}
      {isSignUpDialogOpen && (
        <SignUpDialog
          error={signUpError}
          isSubmitting={isSubmitting}
          onClose={handleSignUpDialogClose}
          onSubmit={handleSignUp}
          currencyList={currencyList}
        />
      )}
      {isUserDataDialogOpen && (
        <UserDataDialog
          error={submitUserDataError}
          isSubmitting={isUserDataSubmitting}
          onClose={handleUserDataDialogClose}
          onSubmit={handleSubmitUserData}
          countryList={countryList}
          currencyList={currencyList}
          userProfileData={userData}
        />
      )}
    </div>
  );
};

const styles = (theme) => ({
  page: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    padding: '0',
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '0',
    },
  },
  slideContainer: {
    marginLeft: theme.spacing(5),
  },
});

export default withStyles(styles)(Page);

import { Link, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function Breadcrumb({ page, title, classes }) {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <Link to="/" className={classes.link}>
        {t('home')}
      </Link>
      {page === 'news' && (
        <span>
          <span className={classes.link}> &gt; </span>
          <Link to="/news" className={classes.link}>
            {t('news')}
          </Link>
        </span>
      )}
      {page === 'post' && (
        <span className={classes.link}>
          <span className={classes.link}> &gt; </span>
          <Link to="/news" className={classes.link}>
            {t('news')}
          </Link>
          <span className={classes.link}> &gt; </span>
          <Link to={location.pathname} className={classes.link}>
            {title}
          </Link>
        </span>
      )}
    </div>
  );
}
const styles = (theme) => ({
  container: {
    margin: '30px 0',
  },
  link: {
    fontSize: '16px',
    color: '#00000080',
    textDecoration: 'none',
    fontWeight: 500,
  },
});
export default withStyles(styles)(Breadcrumb);
